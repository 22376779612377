import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { routerAbi } from './abi';
import { AppChain } from './types';

export const linea: AppChain<'linea'> = {
  blockExplorers: {
    default: {
      name: 'LineaScan',
      url: 'https://lineascan.build',
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  id: 59144,
  logo: <CurrencyIcon ticker="linea" size={24} />,
  name: 'Linea',
  nativeCurrency: {
    decimals: 18,
    name: 'ETH',
    symbol: 'ETH',
  },
  network: 'linea',
  tokenStandard: 'ERC-20',
  renderLogo: (size) => <CurrencyIcon ticker="linea" size={size} />,
  router: {
    abi: routerAbi,
    address: '0x1D3a30c894F581F0b7295E1bdBED268818318c13',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.linea.build'],
    },
    public: {
      http: ['https://rpc.linea.build'],
    },
  },
  vault: {
    address: '0xF09c6F088057A2EB11AEF885640F0440F3710f8b',
  },
};
