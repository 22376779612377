import { track, Event } from '@rehold-v3/data-layer-client';
import { observer, useLocalObservable } from 'mobx-react-lite';
import React, { CFC, useEffect, useState } from 'react';

import { HappyMomentsType } from 'features/HappyMoments/model/types';

import { PaidModal } from '../../ui';

type HappyMomentsParams = {
  amount: string;
  ticker: string;
  type: HappyMomentsType;
} | null;

type ContextValue = {
  setValues: (value: HappyMomentsParams) => void;
  values: HappyMomentsParams;
};

export const HappyMomentsContext = React.createContext({} as ContextValue);

export const HappyMomentsProvider: CFC = observer(({ children }) => {
  const state = useLocalObservable<ContextValue>(() => ({
    setValues(values: HappyMomentsParams) {
      this.values = values;
    },
    values: null,
  }));
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (state.values) {
      setIsOpen(true);
    }
  }, [state.values]);

  const onCloseCallback = () => {
    state.setValues(null);
  };

  const handleClose = () => {
    if (state.values?.type === 'referral-claim') {
      track(Event.REFERRAL_HAPPY_MOMENT_CLOSED);
    }
    if (state.values?.type === 'dual-claim') {
      track(Event.DUAL_HAPPY_MOMENT_CLAIM_CLOSED);
    }
    setIsOpen(false);
  };

  return (
    <HappyMomentsContext.Provider value={state}>
      <>
        {children}
        <PaidModal isOpen={isOpen} onClose={handleClose} onCloseCallback={onCloseCallback} {...state.values} />
      </>
    </HappyMomentsContext.Provider>
  );
});
