import { Event, track, TrackFunctionProperties } from '@rehold-v3/data-layer-client';
import BigNumber from 'bignumber.js';
import { useState } from 'react';
import { parseUnits } from 'viem';
import { useBalance, useContractWrite, usePrepareContractWrite } from 'wagmi';

import { usePerpetualForm } from 'entities/Perpetual/lib/hooks';
import { waitForUserAction } from 'entities/User';

import { tokenAbi } from 'shared/config/chains/abi';
import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { UNKNOWN_ERROR } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { queryClient } from 'shared/lib/react-query';
import { waitForTransfer } from 'shared/lib/waitForTransfer';

type Props = {
  abi: any | undefined;
  amountEth: string;
  chainId: number;
  ticker: string;
  token: `0x${string}` | undefined;
};

export const useBalanceDeposit = ({ amountEth, token, chainId, ticker, abi }: Props) => {
  const { address } = useAccount();
  const { network: currentNetwork } = useNetwork();

  const {
    balancesQuery: { refetch: refetchTradingBalance },
  } = usePerpetualForm();

  const {
    data: balance,
    isLoading: isBalanceLoading,
    isError: isBalanceError,
    refetch: refetchTokenBalance,
  } = useBalance({ address, token, chainId, enabled: !!token });

  const isSufficientFunds =
    balance &&
    BigNumber(balance.value.toString()).gte(parseUnits(amountEth as `${number}`, balance.decimals).toString());

  const prepareArgs = {
    abi: (abi || tokenAbi) as typeof tokenAbi,
    address: token,
    args: [
      currentNetwork.vault?.address as `0x${string}`,
      balance ? parseUnits(amountEth as `${number}`, balance.decimals) : BigInt(0),
    ],
    functionName: 'transfer',
    account: address!,
  } as const;

  const { config, error: transferPrepareError } = usePrepareContractWrite({
    ...prepareArgs,
    chainId: currentNetwork?.id,
    enabled: !!balance,
    cacheTime: 0,
    scopeKey: `deposit::${currentNetwork?.id}-${token}`,
  });

  const {
    isLoading: isTransferContractLoading,
    reset,
    writeAsync: makeTransfer,
    error: transferContractError,
  } = useContractWrite(config.request ? { ...config, request: { ...config.request } } : config);

  const [isTransactionLoading, setIsTransactionLoading] = useState(false);

  const transfer = async (trackParams: TrackFunctionProperties<Event.PERPETUAL_DEPOSIT_ATTEMPTED>) => {
    try {
      if (!address) throw new Error('Account is not connected for deposit');

      track(Event.PERPETUAL_DEPOSIT_ATTEMPTED, trackParams);

      setIsTransactionLoading(true);

      const transactionResult = await waitForUserAction(makeTransfer?.());

      await waitForTransfer({
        address,
        chainId,
        txHash: transactionResult.hash,
        type: 'DEPOSIT',
        amount: amountEth,
        ticker: ticker.toUpperCase(),
      });

      queryClient.invalidateQueries([`perpetual-balance-${address}`]);

      refetchTokenBalance();
      refetchTradingBalance();
    } catch (error: any) {
      track(Event.PERPETUAL_DEPOSIT_FAILED, { ...trackParams, error: error.message ?? UNKNOWN_ERROR });

      reset();
      logger.error(error);
    } finally {
      setIsTransactionLoading(false);
    }
  };

  return {
    isLoading: isTransactionLoading,
    isPreLoading: isBalanceLoading || isTransferContractLoading,
    isError: isBalanceError || !!transferPrepareError || !!transferContractError,
    isSufficientFunds,
    deposit: transfer,
  };
};
