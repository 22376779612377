import { calculate } from '@rehold-v3/dual-calculator';
import { useMemo } from 'react';

import { DualTariff } from 'entities/Dual';
import { useRate } from 'entities/Rates';

type Args = {
  currentTariff: DualTariff;
  inputAmount: string;
  inputTicker: string;
  skip?: boolean;
};

export const useCalculateOutput = ({ currentTariff, inputAmount, inputTicker, skip }: Args) => {
  const baseTicker = currentTariff?.baseTicker || '';
  const quoteTicker = currentTariff?.quoteTicker || '';
  const rateObj = useRate({
    from: baseTicker,
    skip,
    to: quoteTicker,
    inputTicker,
  });

  const output = useMemo(() => {
    if (!currentTariff || !rateObj?.ready) {
      return;
    }

    return calculate(
      {
        apr: currentTariff?.apr,
        baseTicker,
        inputAmount,
        inputTicker,
        quoteTicker,
        stakingPeriod: currentTariff?.stakingPeriod,
      },
      rateObj.price,
    );
  }, [baseTicker, quoteTicker, inputTicker, currentTariff, inputAmount, rateObj]);

  return { output, rateObj };
};
