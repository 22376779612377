import { calculate } from '@rehold-v3/dual-calculator';
import { useDeferredValue, useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useRate } from 'entities/Rates';

import { DashboardFormValues } from '../model/form';

export const useCalculateOutput = () => {
  const { control } = useFormContext<DashboardFormValues>();
  const [inputAmountField, inputTickerField, currentTariff] = useWatch({
    control,
    name: ['inputAmount', 'inputTicker', 'tariff'],
  });

  const defferedInputAmount = useDeferredValue(inputAmountField || 0);
  const defferedInputTicker = useDeferredValue(inputTickerField);

  const baseTicker = currentTariff?.baseTicker || '';
  const quoteTicker = currentTariff?.quoteTicker || '';

  const rateObj = useRate({ from: baseTicker, to: quoteTicker, inputTicker: defferedInputTicker });

  const output = useMemo(() => {
    if (!currentTariff || !rateObj?.ready) {
      return;
    }

    return calculate(
      {
        apr: currentTariff?.apr,
        baseTicker,
        inputAmount: defferedInputAmount,
        inputTicker: defferedInputTicker,
        quoteTicker,
        stakingPeriod: currentTariff?.stakingPeriod,
      },
      rateObj.price,
    );
  }, [baseTicker, quoteTicker, defferedInputTicker, currentTariff, defferedInputAmount, rateObj]);

  return { output };
};
