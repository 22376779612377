import { useTheme } from '@mui/material';
import { formatAmount } from '@rehold-v3/formatters';
import { observer } from 'mobx-react-lite';
import React, { FC, useState, useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { DualType } from 'entities/Dual';
import { useGetDualTokensQuery } from 'entities/Token';

import { Button, PageHeader, Modal } from 'shared/ui';

import { ClaimFormValues } from '../../model/types';
import { ModalContent } from '../ModalContent';

type ClaimDualButtonProps = {
  dual: DualType;
  onError?: () => void;
  onStart?: () => void;
  onSuccess?: (dual: DualType) => void;
};

export const ClaimDualButton: FC<ClaimDualButtonProps> = observer(({ dual, onSuccess, ...props }) => {
  const { t } = useTranslation();
  const { tokens } = useGetDualTokensQuery();
  const { colors } = useTheme();

  const [isOpen, setIsOpen] = useState(false);

  const form = useForm<ClaimFormValues>({
    defaultValues: {
      receiver: '',
    },
  });

  useEffect(
    () => () => {
      if (!isOpen) form.reset();
    },
    [isOpen, form],
  );

  const onOpen = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setIsOpen(true);
  };
  const onClose = () => {
    setIsOpen(false);
  };

  const onSuccessClaim = (dual: DualType) => {
    onSuccess?.(dual);
    setIsOpen(false);
  };

  const claimDisabled = dual.l2AutoReplayed || dual.l2Replayed;

  return (
    <>
      <Button onClick={onOpen} disabled={claimDisabled} variant="transparent" data-id="claim-dual">
        {t('dual.card.claim')} {formatAmount({ symbol: dual.outputTicker, value: dual.outputAmount })}{' '}
        {tokens[dual.outputTicker]?.symbol}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeIcon
        header={
          <PageHeader showLeftButton={false} pb={20} borderBottom={`1px solid ${colors['secondary-02']}`}>
            {t('dual.claimConfirmation')}
          </PageHeader>
        }
      >
        <FormProvider {...form}>
          <ModalContent {...props} onSuccess={onSuccessClaim} dual={dual} />
        </FormProvider>
      </Modal>
    </>
  );
});
