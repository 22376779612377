import { useMediaQuery } from '@mui/material';
import { ConnectKitButton } from '@rehold-v3/connectkit';
import { Event, track } from '@rehold-v3/data-layer-client';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { HotjarEvents, trackHotjar } from 'features/HotJar';
import { TOUR_CLASSES } from 'features/OnboardingTour';

import { useAccount } from 'shared/hooks';
import { Button } from 'shared/ui';

export const NavConnect: React.FC = () => {
  const location = useLocation();
  const { t } = useTranslation();
  const { isConnected } = useAccount();

  const isWideMobile = useMediaQuery('(max-width: 1024px)', { noSsr: true });

  if (isConnected) return;

  return (
    <ConnectKitButton.Custom>
      {({ show }) => {
        const onPress = () => {
          trackHotjar(HotjarEvents.WALLET_CONNECT_CLICK);
          track(Event.WALLET_CONNECT_CLICKED, { from: location.pathname });
          show?.();
        };
        return (
          <Button
            variant="primary"
            size="small"
            onClick={onPress}
            borderRadius={32}
            ml={8}
            className={TOUR_CLASSES.HEADER_CONNECT.class}
            data-id="header-connect"
          >
            {t(`modals.connectWallet.${isWideMobile ? 'shortTitle' : 'title'}`)}
          </Button>
        );
      }}
    </ConnectKitButton.Custom>
  );
};
