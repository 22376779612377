import useResizeObserver from '@react-hook/resize-observer';
import { CalculateOutput } from '@rehold-v3/dual-calculator';
import { formatAmount, formatPrice } from '@rehold-v3/formatters';
import classnames from 'classnames';
import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { DualTokenIcon } from 'entities/Token';
import { useGetDualTokensAllQuery } from 'entities/Token/model/useGetDualTokensAllQuery';

import { Box } from 'shared/ui';

import { Chart } from './Chart';
import styles from './DualChart.module.scss';

interface DualChartProps {
  baseTicker: string;
  calculation: CalculateOutput;
  chainId: number;
  className?: string;
  entryPrice: string;
  quoteTicker: string;
  stakingPeriod: string;
}

export const DualChart: React.CFC<DualChartProps> = ({
  baseTicker,
  calculation,
  children,
  className,
  entryPrice,
  quoteTicker,
  stakingPeriod,
  chainId,
}) => {
  const { t } = useTranslation();
  const containerRef = useRef<HTMLDivElement>(null);
  const [size, setSize] = useState<DOMRectReadOnly | null>(null);
  const { tokens } = useGetDualTokensAllQuery();

  useResizeObserver(containerRef, (entry) => setSize(entry.contentRect));

  const { down, up } = calculation;

  return (
    <div className={classnames(styles.container, className)} ref={containerRef}>
      <div>
        <Chart height={size?.height} width={size?.width} />
      </div>
      <div className={styles.info}>
        <div className={styles.infoLeft}>
          <div className={styles.infoLeftUp}>
            <div className={styles.entryPriceLabel}>{t('charts.entryPrice')}</div>
            <div className={styles.entryPriceValue}>
              {formatPrice({ from: baseTicker, to: quoteTicker, value: entryPrice })}
            </div>
          </div>
          <div className={styles.infoLeftDown}>
            <div className={styles.infoLabel}>
              {t('charts.period')}: {stakingPeriod}
            </div>
          </div>
        </div>
        <div className={styles.infoRight}>
          <div className={styles.infoRightUp}>
            <div className={styles.profitCurrency}>
              <DualTokenIcon ticker={up.outputTicker} />
            </div>
            <Box gap="2px">
              <div className={styles.infoLabel}>{t('charts.profit')}</div>
              <div className={styles.profitText}>
                {`+${formatAmount({ symbol: up.outputTicker, value: up.profit })} ${
                  tokens[chainId]?.[up.outputTicker]?.symbol
                }`}
              </div>
            </Box>
            <Box gap="2px">
              <div className={styles.infoLabel}>{t('charts.totalReceive')}</div>
              <div className={styles.totalText}>
                {`${formatAmount({
                  symbol: up.outputTicker,
                  value: up.outputAmount,
                })} ${tokens[chainId]?.[up.outputTicker]?.symbol}`}
              </div>
            </Box>
          </div>
          <div className={styles.infoRightDown}>
            <div className={styles.profitCurrency}>
              <DualTokenIcon ticker={down.outputTicker} />
            </div>
            <Box gap="2px">
              <div className={styles.infoLabel}>{t('charts.profit')}</div>
              <div className={styles.profitText}>
                {`+${formatAmount({
                  symbol: down.outputTicker,
                  value: down.profit,
                })} ${tokens[chainId]?.[down.outputTicker]?.symbol}`}
              </div>
            </Box>
            <Box gap="2px">
              <div className={styles.infoLabel}>{t('charts.totalReceive')}</div>
              <div className={styles.totalText}>
                {`${formatAmount({
                  symbol: down.outputTicker,
                  value: down.outputAmount,
                })} ${tokens[chainId]?.[down.outputTicker]?.symbol}`}
              </div>
            </Box>
          </div>
        </div>
      </div>
      {children}
    </div>
  );
};
