import { OkxConnector } from '@rehold-v3/connectkit';

import { supportedChains } from 'shared/config';

import { getImageWallet } from '../../getImages';
import { AppWallet } from '../../types';

export const okx: AppWallet = {
  connector: new OkxConnector({
    chains: supportedChains as any,
  }),
  iconPath: getImageWallet('okxwallet'),
  id: 'okxwallet',
  name: 'OKX',
};
