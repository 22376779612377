import { getImageChain } from 'entities/Chain';

import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { routerAbi } from './abi';
import { AppChain } from './types';

export const rootstockTestnet: AppChain<'rootstock-testnet'> = {
  disabled: false,
  id: 31,
  name: 'Rootstock Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'tRBTC',
    symbol: 'tRBTC',
  },
  network: 'rootstock-testnet',
  tokenStandard: 'Rootstock',
  testnet: true,
  logo: <CurrencyIcon url={getImageChain('rootstock')} ticker="rbtc" size={24} />,
  renderLogo: (size) => <CurrencyIcon url={getImageChain('rootstock')} ticker="rbtc" size={size} />,
  rpcUrls: {
    default: {
      http: ['https://public-node.testnet.rsk.co'],
    },
    public: {
      http: ['https://public-node.testnet.rsk.co'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Rootstock Testnet',
      url: 'https://explorer.testnet.rootstock.io/',
    },
  },
  router: {
    abi: routerAbi,
    address: '0x1D3a30c894F581F0b7295E1bdBED268818318c13',
  },
  vault: {
    address: '0xF09c6F088057A2EB11AEF885640F0440F3710f8b',
  },
  tokens: {
    rbtc: { address: '0x0000000000000000000000000000000000000000' },
    wrbtc: { address: '0x69fe5cec81d5ef92600c1a0db1f11986ab3758ab' },
  },
};
