import { Event, track } from '@rehold-v3/data-layer-client';
import { formatAmount } from '@rehold-v3/formatters';
import omit from 'lodash.omit';
import { observer } from 'mobx-react-lite';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { waitForTransaction as waitForTransactionWagmi } from 'wagmi/actions';

import { DualType } from 'entities/Dual';
import { useGetDualTokensQuery } from 'entities/Token';

import { useNetwork } from 'shared/hooks/network';
import { waitForTransaction } from 'shared/lib';
import { estimateGasClaim } from 'shared/lib/estimateGas';
import { logger } from 'shared/lib/logger';
import { Box, Button, PageContent } from 'shared/ui';

import { useClaimDual } from '../../lib';
import { ClaimFormValues } from '../../model/types';
import { GasPrice } from '../GasPrice';
import { ReceiveField } from '../ReceiveField';

type ModalContentProps = {
  dual: DualType;
  onError?: () => void;
  onStart?: () => void;
  onSuccess?: (dual: DualType) => void;
};

export const ModalContent: FC<ModalContentProps> = observer(({ dual, onError, onStart, onSuccess }) => {
  const { t } = useTranslation();
  const { chainId } = useNetwork();
  const { tokens } = useGetDualTokensQuery();
  const [isLoading, setIsLoading] = useState(false);
  const [isShowReceive, setIsReceive] = useState(false);

  const { handleSubmit, reset } = useFormContext<ClaimFormValues>();

  const { writeAsync } = useClaimDual(dual);

  const estimateGas = useCallback(() => estimateGasClaim(chainId!), [chainId]);

  const trackedDual = useMemo(() => omit(dual, ['chartData', 'closeCalculate']), [dual]);

  const handleClaim = useCallback(
    async (values: ClaimFormValues, e?: React.BaseSyntheticEvent) => {
      e?.stopPropagation();

      const trackProperties = { ...trackedDual, receiver: values.receiver };

      onStart?.();
      setIsLoading(true);

      track(Event.DUAL_CLAIM_CLICKED, trackProperties);

      try {
        const transactionResult = await writeAsync?.(values.receiver);
        track(Event.DUAL_CLAIM_ATTEMPTED, trackProperties);

        await waitForTransaction(waitForTransactionWagmi({ hash: transactionResult?.hash! }), transactionResult?.hash);
        track(Event.DUAL_CLAIM_SUCCEEDED, trackProperties);

        onSuccess?.(dual);
      } catch (e: any) {
        onError?.();
        track(Event.DUAL_CLAIM_FAILED, {
          ...trackedDual,
          error: e?.message ?? 'Unknown error',
          receiver: values.receiver,
        });

        logger.error(e);
        setIsLoading(false);
      }
    },
    [writeAsync, trackedDual, dual, onSuccess, onStart, onError],
  );

  const onChangeShowReceive = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsReceive(event.target.checked);
    if (!event.target.checked) {
      reset();
    }
  };

  return (
    <PageContent
      maxHeight="100%"
      height="100%"
      mt={{ default: 12, desktop: 24, tablet: 16 }}
      gap={{ default: 12, desktop: 24, tablet: 16 }}
      px={{ default: 12, desktop: 24, tablet: 16 }}
      pb={{ default: 12, desktop: 16, tablet: 12 }}
    >
      <Box gap={16}>
        <ReceiveField enabled={isShowReceive} onChange={onChangeShowReceive} />
        <GasPrice estimateGas={estimateGas} />
      </Box>
      <Button
        onClick={handleSubmit(handleClaim)}
        loading={isLoading}
        disabled={isLoading || dual.l2AutoReplayed || dual.l2Replayed}
        variant="secondary"
        data-id="claim-dual"
      >
        {t('dual.card.claim')} {formatAmount({ symbol: dual.outputTicker, value: dual.outputAmount })}{' '}
        {tokens[dual.outputTicker]?.symbol}
      </Button>
    </PageContent>
  );
});
