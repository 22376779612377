import { Event, track } from '@rehold-v3/data-layer-client';
import { toast } from 'react-hot-toast';

import { useSignAuthMessage, storageSignature } from 'features/Auth';

import { DualType, useReplayDualMutation } from 'entities/Dual';
import { waitForUserAction } from 'entities/User';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { extractError } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { TransactionToast } from 'shared/ui/Toasts/TransactionConfirmation/TransactionToast';

type Args = {
  dual: DualType;
  enabled: boolean;
  isShowChanged?: boolean;
  tariffId: string;
  trackParams: any;
};

export const useReplay = ({ dual: { id }, isShowChanged = false, trackParams }: Args) => {
  const { mutateAsync: replayDual, isError, isLoading } = useReplayDualMutation();
  const { network } = useNetwork();
  const { address } = useAccount();

  const { signAuthMessage, validateSign } = useSignAuthMessage();

  const onReplay = async (autoReplay: boolean) => {
    const isSigned = await validateSign();

    if (!isSigned) {
      try {
        const isSuccess = await waitForUserAction(signAuthMessage(), 'message');

        if (!isSuccess) return;
      } catch {
        return;
      }
    }

    const userSignature = storageSignature.get(address!);
    if (!userSignature) {
      return Promise.reject(new Error('not signature'));
    }

    track(Event.DUAL_REPLAY_CLICKED, { ...trackParams, autoReplay, changed: isShowChanged });

    try {
      track(Event.DUAL_REPLAY_ATTEMPTED, { ...trackParams, autoReplay });

      const result = await replayDual({ chainId: network?.id!, id, signature: userSignature });

      track(Event.DUAL_REPLAY_SUCCEEDED, { ...trackParams, autoReplay });

      toast.custom((t) => <TransactionToast toast={t} status="success" />, {
        duration: 3000,
      });

      if ('data' in result) {
        return { id: result.data.id };
      }

      return null;
    } catch (e: any) {
      logger.error(e);
      track(Event.DUAL_REPLAY_FAILED, { ...trackParams, autoReplay, error: extractError(e) });

      throw e;
    }
  };

  return { isError, isLoading, onReplay };
};
