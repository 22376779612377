import { ZerionConnector } from '@rehold-v3/connectkit';

import { supportedChains } from 'shared/config';

import { getImageWallet } from '../../getImages';
import { AppWallet } from '../../types';

export const zerion: AppWallet = {
  connector: new ZerionConnector({
    chains: supportedChains as any,
  }),
  iconPath: getImageWallet('zerion'),
  id: 'zerion',
  name: 'Zerion',
};
