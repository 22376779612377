import i18next from 'i18next';
import { useEffect, FC, CFC, FunctionComponent, SVGProps } from 'react';
import { initReactI18next, I18nextProviderProps, I18nextProvider, useTranslation } from 'react-i18next';

import {
  LanguageDeIcon,
  LanguageEnIcon,
  LanguageEsIcon,
  LanguageFrIcon,
  LanguageIdIcon,
  LanguageItIcon,
  LanguageJaIcon,
  LanguageKoIcon,
  LanguagePtIcon,
  LanguageRuIcon,
  LanguageViIcon,
  LanguageZhIcon,
} from 'shared/assets';

import de from './de.json';
import en from './en.json';
import es from './es.json';
import fr from './fr.json';
import id from './id.json';
import it from './it.json';
import jp from './jp.json';
import kr from './kr.json';
import pt from './pt.json';
import ru from './ru.json';
import vi from './vi.json';
import zh from './zh.json';

export { dateFnsLocales } from './date-fns';

export const DEFAULT_LANGUAGE = 'en';

export const SUPPORTED_LANGUAGES: { [key: string]: string } = {
  de: 'Deutsch',
  en: 'English',
  es: 'Español',
  fr: 'Français',
  id: 'Bahasa',
  it: 'Italiano',
  ja: '日本語',
  ko: '한국어',
  pt: 'Português',
  ru: 'Русский',
  vi: 'Tiếng Việt',
  zh: '简体中文',
};

export const LANGUAGES_ICONS: { [key: string]: FunctionComponent<SVGProps<SVGSVGElement>> } = {
  de: LanguageDeIcon,
  en: LanguageEnIcon,
  es: LanguageEsIcon,
  fr: LanguageFrIcon,
  id: LanguageIdIcon,
  it: LanguageItIcon,
  ja: LanguageJaIcon,
  ko: LanguageKoIcon,
  pt: LanguagePtIcon,
  ru: LanguageRuIcon,
  vi: LanguageViIcon,
  zh: LanguageZhIcon,
};

export const locales = {
  de,
  en,
  es,
  fr,
  id,
  it,
  ja: jp,
  ko: kr,
  pt,
  ru,
  vi,
  zh,
};

export const connectKitLanguages = {
  en: 'en-US',
  es: 'es-ES',
  fr: 'fr-FR',
  ja: 'ja-JP',
  pt: 'pt-BR',
  zh: 'zh-CN',
} as const;

const LOCAL_STORAGE_I18N_KEY = 'rehold_i18n';

const getLanguageParam = () => {
  const params = new URLSearchParams(window?.location?.search);
  const language = params.get('language');

  if (language && SUPPORTED_LANGUAGES[language]) {
    return language;
  }

  return '';
};

const getLanguage = () => {
  const fallbackToRussian = new Set(['kk', 'uk', 'be']);
  const cached = getLanguageParam() || localStorage.getItem(LOCAL_STORAGE_I18N_KEY);

  if (cached && SUPPORTED_LANGUAGES[cached]) {
    return cached;
  }

  const supported =
    navigator.languages.find((code) => {
      // for language codes like en-US
      const [lang] = code.split('-');

      return !!SUPPORTED_LANGUAGES[lang] || fallbackToRussian.has(lang);
    }) ?? DEFAULT_LANGUAGE;

  if (fallbackToRussian.has(supported)) {
    return 'ru';
  }

  // for language codes like en-US
  const [lang] = supported.split('-');

  return lang;
};

export const i18n = i18next.createInstance();

i18n.use(initReactI18next).init({
  fallbackLng: DEFAULT_LANGUAGE,
  initImmediate: true,
  interpolation: { escapeValue: false },
  lng: getLanguage(),
  ns: ['translation'],
  resources: locales,
  supportedLngs: Object.keys(SUPPORTED_LANGUAGES),
});

const I18nextProviderSpy: FC = () => {
  const {
    i18n: { language },
  } = useTranslation();

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_I18N_KEY, language);
  }, [language]);

  return null;
};

export const I18nextProviderWithSpy: CFC<I18nextProviderProps> = ({ children, ...rest }) => (
  <I18nextProvider {...rest}>
    <I18nextProviderSpy />
    {children}
  </I18nextProvider>
);

export default locales;
