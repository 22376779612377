import { Skeleton, useMediaQuery } from '@mui/material';
import { formatAmount, formatPrice } from '@rehold-v3/formatters';
import BigNumber from 'bignumber.js';
import { useTranslation } from 'react-i18next';

import { usePerpetualForm } from 'entities/Perpetual';
import { useRateExtended, useRateInterval } from 'entities/Rates';
import { RateProduct } from 'entities/Rates/model/types';

import { useAccount } from 'shared/hooks';
import { formatWithCommas } from 'shared/lib';
import { formatPercent } from 'shared/lib/formats/format';
import { Box, Text } from 'shared/ui';
import { StyledText } from 'shared/ui/Error/styled';

import './index.scss';

export const PerpetualsFormSummary = () => {
  const { t } = useTranslation();

  const { isConnected } = useAccount();

  const { state, form } = usePerpetualForm();

  const { data: rateExtendedData } = useRateExtended({
    symbol: state.tariff ? `${state.tariff.baseTicker}/${state.tariff.quoteTicker}` : null,
    product: RateProduct.PERPETUAL,
  });

  const price = useRateInterval({
    from: state.tariff?.baseTicker,
    to: state.tariff?.quoteTicker,
    product: RateProduct.PERPETUAL,
  });
  const inputQuoteRate = useRateInterval({
    from: state?.inputTicker,
    to: state.tariff?.quoteTicker,
    product: RateProduct.DEFAULT,
  });

  const entryPrice = state.isShort ? price.bid ?? rateExtendedData?.[0]?.bid : price.ask ?? rateExtendedData?.[0]?.ask;
  const entryPriceFormatted =
    state.tariff && (price.ready || !!rateExtendedData)
      ? formatWithCommas(
          formatPrice({
            value: entryPrice,
            from: state.tariff?.baseTicker,
            to: state.tariff?.quoteTicker,
          }),
        )
      : null;

  const openingFeeAmount = state.tariff
    ? BigNumber(state.inputAmount || 0)
        .multipliedBy(BigNumber(state.leverage || 0))
        .multipliedBy(state.tariff.openingFee ?? 0)
    : null;
  const openingFeeFormatted =
    !!state.tariff && !!openingFeeAmount && !openingFeeAmount.isNaN() && openingFeeAmount.isFinite()
      ? formatWithCommas(
          formatAmount({
            value: openingFeeAmount,
            symbol: state.inputTicker,
            roundingMode: BigNumber.ROUND_CEIL,
          }),
        )
      : null;
  const isOpeningFeeReady =
    openingFeeFormatted || (openingFeeAmount && (openingFeeAmount.isNaN() || !openingFeeAmount.isFinite()));

  const size = BigNumber(state.inputAmount || 0)
    .multipliedBy(state.leverage || 0)
    .minus(openingFeeAmount || 0);
  const sizeFormatted = formatWithCommas(
    formatAmount({
      value: size,
      symbol: state.inputTicker,
      roundingMode: BigNumber.ROUND_DOWN,
    }),
  );

  let liquidationPrice;
  if (state.isShort) {
    const collateralAmount = BigNumber(state.inputAmount || 0)
      .times(state.leverage || 0)
      .minus(openingFeeAmount ?? 0)
      .times(inputQuoteRate.price);
    const borrowedAmount = BigNumber(state.inputAmount || 0)
      .times(BigNumber(state.leverage).minus(1))
      .times(inputQuoteRate.price)
      .div(entryPrice);
    liquidationPrice = BigNumber(collateralAmount).div(borrowedAmount);
  } else {
    const collateralAmount = BigNumber(state.inputAmount || 0)
      .times(state.leverage || 0)
      .minus(openingFeeAmount ?? 0)
      .times(inputQuoteRate.price)
      .div(entryPrice);
    const borrowedAmount = BigNumber(state.inputAmount || 0)
      .times(BigNumber(state.leverage || 0).minus(1))
      .times(inputQuoteRate.price);
    liquidationPrice = BigNumber(borrowedAmount).div(collateralAmount);
  }
  const liquidationPriceFormatted =
    !!state.tariff && !!liquidationPrice && !liquidationPrice.isNaN() && liquidationPrice.isFinite()
      ? formatWithCommas(
          formatPrice({
            value: liquidationPrice,
            from: state.tariff.baseTicker,
            to: state.tariff.quoteTicker,
          }),
        )
      : null;
  const isLiquidationPriceReady =
    liquidationPriceFormatted || (liquidationPrice && (liquidationPrice.isNaN() || !liquidationPrice.isFinite()));

  const borrowFee = BigNumber(state.tariff?.apr || 0)
    .div(365)
    .div(24)
    .multipliedBy(state.tariff?.settlementPeriodInHours || 0);
  const borrowFeeFormatted = state.tariff ? formatWithCommas(formatPercent({ value: borrowFee, decimals: 4 })) : null;
  const isBorrowFeeReady = borrowFeeFormatted || (borrowFee && (borrowFee.isNaN() || !borrowFee.isFinite()));

  const isSmallMobile = useMediaQuery('(max-width: 534px)', { noSsr: false });

  return (
    <Box className="perpetuals-form-summary">
      <Box className="perpetuals-form-summary-item">
        <Text text="app-14-regular" color="gray-01">
          {t('perpetuals.form.entryPrice')}
        </Text>
        <Box className="perpetuals-form-summary-text-container">
          {entryPriceFormatted && (
            <Text
              className="perpetuals-form-summary-text"
              text="app-14-regular"
              color="gray-01"
              title={entryPriceFormatted}
            >
              {entryPriceFormatted}
            </Text>
          )}
          {!entryPriceFormatted && (
            <Box className="perpetuals-form-summary-skeleton">
              <Skeleton
                width={82}
                height={18}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 1)' }}
                animation="wave"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box className="perpetuals-form-summary-item">
        <Text text="app-14-regular" color="gray-01">
          {t('perpetuals.form.liquidationPrice')}
        </Text>
        <Box className="perpetuals-form-summary-text-container">
          {isLiquidationPriceReady && (
            <Text className="perpetuals-form-summary-text" text="app-14-regular" color="gray-01">
              {liquidationPriceFormatted ??
                formatPrice({
                  value: 0,
                  from: state.tariff?.baseTicker || '',
                  to: state.tariff?.quoteTicker || '',
                })}
            </Text>
          )}
          {!isLiquidationPriceReady && (
            <Box className="perpetuals-form-summary-skeleton">
              <Skeleton
                width={82}
                height={18}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 1)' }}
                animation="wave"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box className="perpetuals-form-summary-item">
        <Text text="app-14-regular" color="gray-01">
          {t('perpetuals.form.openFee')}
        </Text>
        <Box className="perpetuals-form-summary-text-container">
          {isOpeningFeeReady && (
            <Text className="perpetuals-form-summary-text" text="app-14-regular" color="gray-01">
              {openingFeeFormatted} {state.inputTicker.toUpperCase()}
            </Text>
          )}
          {!isOpeningFeeReady && (
            <Box className="perpetuals-form-summary-skeleton">
              <Skeleton
                width={82}
                height={18}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 1)' }}
                animation="wave"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box className="perpetuals-form-summary-item">
        <Text text="app-14-regular" color="gray-01">
          {t('perpetuals.form.borrowFee')}
        </Text>
        <Box className="perpetuals-form-summary-text-container">
          {isBorrowFeeReady && (
            <Text className="perpetuals-form-summary-text" text="app-14-regular" color="gray-01">
              {borrowFeeFormatted}% / {state.tariff?.settlementPeriodInHours}
              {t('perpetuals.form.tooltip.hours')}
            </Text>
          )}
          {!isBorrowFeeReady && (
            <Box className="perpetuals-form-summary-skeleton">
              <Skeleton
                width={82}
                height={18}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 1)' }}
                animation="wave"
              />
            </Box>
          )}
        </Box>
      </Box>
      <Box className="perpetuals-form-summary-item">
        <Text text="app-14-regular" color="gray-01">
          {t('perpetuals.form.size')}
        </Text>
        <Box className="perpetuals-form-summary-text-container">
          <Text className="perpetuals-form-summary-text" text="app-14-regular" color="gray-01" title={sizeFormatted}>
            {sizeFormatted}{' '}
          </Text>
          <Text text="app-14-regular" color="gray-01">
            {state.inputTicker.toUpperCase()}
          </Text>
        </Box>
        {isConnected && state.tariff && form.formState.errors.root && (
          <StyledText
            $align="left"
            className="triangle-up"
            position="absolute"
            bottom={0}
            zIndex={10}
            borderRadius="8px"
            px={8}
            py={4}
            bgColor="red-01"
            text="app-12-regular"
            left={0}
            right="unset"
            maxWidth={isSmallMobile ? '100%' : '534px'}
          >
            {form.formState.errors.root.message}
          </StyledText>
        )}
      </Box>
    </Box>
  );
};
