import { Skeleton } from '@mui/material';
import { Event, track } from '@rehold-v3/data-layer-client';
import { formatAmount } from '@rehold-v3/formatters';
import BigNumber from 'bignumber.js';
import cx from 'classnames';
import { useState } from 'react';

import { usePerpetualForm } from 'entities/Perpetual/lib/hooks/usePerpetualForm';
import { useRate } from 'entities/Rates';

import { CheckIcon, ExpandIcon } from 'shared/assets';
import { useAccount } from 'shared/hooks';
import { Box, Menu, Text } from 'shared/ui';

import './index.scss';

export const PerpetualsFormBalanceSelector = () => {
  const { isConnected } = useAccount();

  const [open, setOpen] = useState(false);

  const { state, balances, balancesQuery, changeInput } = usePerpetualForm();

  const rates = useRate({ from: state.balance?.ticker, to: 'usd' });

  const balanceUsd = BigNumber(state.balance?.amount || 0)
    .multipliedBy(rates.price)
    .toNumber();

  const isLoading = (balancesQuery.isLoading || !state.balance) && isConnected;

  const handleInputChange = (ticker: string) => () => {
    changeInput(ticker);
    setOpen(false);

    track(Event.PERPETUAL_INPUT_ASSET_SELECTED, { inputTicker: ticker });
  };

  return (
    <Menu
      backgroundColor="black-01"
      borderColor="secondary-02"
      fullWidth
      trigger={
        <Box className={cx('perpetuals-form-balance-selector', { active: open })}>
          <Box className="perpetuals-form-balance-selector-token">
            {isLoading && (
              <Skeleton
                width={32}
                height={32}
                sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '50%', transform: 'scale(1, 1)' }}
                animation="wave"
              />
            )}
            {!isLoading && (
              <img
                alt="token"
                src={`/static/tickers/${state.balance?.ticker}.svg`}
                className="perpetuals-form-balance-selector-token-img"
              />
            )}
            <Box className="perpetuals-form-balance-selector-token-info">
              <Box>
                <Text text="app-16-medium">
                  {!isLoading &&
                    state.balance &&
                    formatAmount({
                      value: state.balance.amount,
                      symbol: state.balance.ticker,
                      roundingMode: BigNumber.ROUND_DOWN,
                    })}
                  {(isLoading || !state.balance) && '—'}
                </Text>
                <Text text="app-16-medium" color="gray-01">
                  {!isLoading && state.balance && state.balance.ticker.toUpperCase()}
                  {(isLoading || state.balance) && ''}
                </Text>
              </Box>
              <Text text="app-12-medium" color="gray-01">
                {!isLoading &&
                  `≈${formatAmount({ value: balanceUsd, symbol: 'usdt', roundingMode: BigNumber.ROUND_DOWN })} USD`}
                {isLoading && '—'}
              </Text>
            </Box>
          </Box>
          <ExpandIcon width={16} height={16} />
        </Box>
      }
      isOpen={open}
      toggle={(value) => {
        if (state.balance) setOpen(value);
      }}
      forceCallback
      forceDesktopLayout
      clickable
      hoverable={false}
      showCorner={false}
      px={0}
      py={0}
      menuGap={2}
      width="100%"
      minWidth="0px"
    >
      <Box width="100%" zIndex="9999999">
        {balances?.map((balance) => (
          <Box
            className={cx('perpetuals-form-balance-selector-item', {
              active: balance.ticker === state.inputTicker,
            })}
            onClick={handleInputChange(balance.ticker)}
          >
            <Box>
              <img
                alt="token"
                src={`/static/tickers/${balance.ticker}.svg`}
                className="perpetuals-form-balance-selector-item-img"
              />
              <Box className="perpetuals-form-balance-selector-item-info">
                <Text text="app-14-medium">
                  {formatAmount({
                    value: balance.amount,
                    symbol: balance.ticker,
                    roundingMode: BigNumber.ROUND_DOWN,
                  })}
                </Text>
                <Text text="app-14-medium" color="gray-01">
                  {balance.ticker.toUpperCase()}
                </Text>
              </Box>
            </Box>
            <CheckIcon width={16} height={16} color="var(--primary-01)" />
          </Box>
        ))}
        {/* <Box height="1px" width="100%" backgroundColor="secondary-02" />
        <Box className="perpetuals-form-balance-selector-item">
          <PlusIcon className="perpetuals-form-balance-selector-item-img" />
          <Box className="perpetuals-form-balance-selector-item-info">
            <Text text="app-14-medium">Add Balance</Text>
          </Box>
        </Box> */}
      </Box>
    </Menu>
  );
};
