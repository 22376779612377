import React, { memo } from 'react';

import { Box } from 'shared/ui';

import { NavConnect } from './NavConnect';
import { NavMain } from './NavMain';
// import { NavNotifications } from './NavNotifications';
import { NavPoints } from './NavPoints';
// import { NavSupport } from './NavSupport';
import { NavWallet } from './NavWallet';
import { MenuContextProvider, SharedMenu } from './SharedMenu';

export const NavContent = memo(() => (
  <MenuContextProvider>
    <Box flexDirection="row" alignItems="center">
      <NavPoints />
      {/* <NavSupport /> */}
      {/* <NavNotifications /> */}
      <NavMain />
      <NavWallet />
      <NavConnect />
      <SharedMenu />
    </Box>
  </MenuContextProvider>
));
