import { formatAmount } from '@rehold-v3/formatters';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { useReferral } from 'features/ReferralProgram/lib';

import { useGetReferralOverviewQuery } from 'entities/Referral';

import { GiftIcon, HintIcon } from 'shared/assets';
import { useNetwork } from 'shared/hooks/network';
import { toBigNumber } from 'shared/lib';
import { Box, Button, Text, Tooltip, ButtonIcon, Spinner } from 'shared/ui';

export const ReferralProgramReferralsAccumulated: React.FC = observer(() => {
  const { t } = useTranslation();
  const { network } = useNetwork();
  const {
    balanceError,
    balanceFetching,
    balanceRefetch,
    claim,
    claimDisabled,
    claimIsLoading,
    minWithdrawalAmount,
    ticker,
    unclaimedBalance,
  } = useReferral();

  const { isLoading } = useGetReferralOverviewQuery();

  const isDisabled = claimIsLoading || toBigNumber(unclaimedBalance).lte(0) || claimDisabled;

  if (isLoading) {
    return (
      <Box height={250} alignItems="center" justifyContent="center">
        <Spinner />
      </Box>
    );
  }

  return (
    <Box>
      <Box flexDirection="row" gap="8px">
        <Text mb={16}>{t('referralProgram.referralsPage.accumulated')}</Text>

        <Tooltip
          title={`${t('referralProgram.referralsPage.referralTooltip')} ${t(
            'referralProgram.referralsPage.minWithdrawal',
            { chain: network?.name, value: minWithdrawalAmount },
          )}`}
        >
          <Box width="max-content">
            <ButtonIcon isOutlined sx={{ padding: '2px' }}>
              <HintIcon width={16} height={16} />
            </ButtonIcon>
          </Box>
        </Tooltip>
      </Box>
      <Box
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        height={64}
        borderColor="secondary-02"
        borderRadius={16}
        p={8}
        mb={24}
        sx={{ borderWidth: 1 }}
      >
        <Box flex={1} px={16} flexDirection="row">
          <Box flexDirection="row" alignItems="center">
            <Box mr={8} color="primary-01">
              <GiftIcon width={24} height={24} />
            </Box>
            <Text color="primary-01">
              {formatAmount({ symbol: ticker, value: unclaimedBalance })}{' '}
              <Text opacity={0.6}>{ticker.toUpperCase()}</Text>
            </Text>
          </Box>
          {balanceFetching && (
            <Box ml={8} alignSelf="center">
              <Spinner size={24} />
            </Box>
          )}
          {balanceError && (
            <Box flexDirection="row" ml={8} alignSelf="center">
              <Box onClick={balanceRefetch}>
                <Text color="red-01" fontWeight="bold">
                  {t('common.fetchRetry')}
                </Text>
              </Box>
            </Box>
          )}
        </Box>
        <Button
          loading={claimIsLoading}
          disabled={isDisabled}
          onClick={claim}
          data-id="claim-referral"
          maxWidth="max-content"
        >
          {t('referralProgram.referralsPage.claim')}
        </Button>
      </Box>
    </Box>
  );
});
