import { Event, track } from '@rehold-v3/data-layer-client';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import { usePerpetualOpenedPositions } from 'entities/Perpetual/lib/hooks';

import { Box, Text } from 'shared/ui';

import './index.scss';

export enum PerpetualsListTab {
  HISTORY = 'history',
  POSITIONS = 'positions',
}

type Props = {
  onTabChange: (tab: PerpetualsListTab) => void;
  tab: PerpetualsListTab;
};

export const PerpetualsListsTabs: React.FC<Props> = ({ tab, onTabChange }) => {
  const { t } = useTranslation();

  const handleTabClick = (tab: PerpetualsListTab) => () => {
    onTabChange(tab);

    track(Event.PERPETUAL_TAB_CLICKED, { tab });
  };

  const { data: opened } = usePerpetualOpenedPositions();

  return (
    <Box className="perpetuals-lists-tabs">
      <Box
        className={cx('perpetuals-lists-tab', { active: tab === PerpetualsListTab.POSITIONS })}
        onClick={handleTabClick(PerpetualsListTab.POSITIONS)}
      >
        <Text text="app-16-medium"> {t('perpetuals.lists.tabs.positions')}</Text>
        {opened && opened.length !== 0 && <Box className="perpetuals-lists-tab-badge">{opened.length}</Box>}
      </Box>
      <Box
        className={cx('perpetuals-lists-tab', { active: tab === PerpetualsListTab.HISTORY })}
        onClick={handleTabClick(PerpetualsListTab.HISTORY)}
      >
        <Text text="app-16-medium"> {t('perpetuals.lists.tabs.history')}</Text>
      </Box>
    </Box>
  );
};
