import { Event, track } from '@rehold-v3/data-layer-client';
import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';

import { FeatureReferralProgram } from 'features/ReferralProgram';

import { PUBLIC_URL } from 'shared/config';
import { useIsMobile } from 'shared/hooks';
import { PathPatterns } from 'shared/lib';
import { PageHeader, PageContent } from 'shared/ui';

export const ReferralProgram = () => {
  const { t } = useTranslation();

  const onLeftButtonPress = useCallback(() => {
    track(Event.BACK_BUTTON_CLICKED, { from: 'referral' });
  }, []);

  const isMobile = useIsMobile();

  return (
    <>
      <Helmet title={t('referralProgram.mainPage.metaTitle')}>
        <link rel="canonical" href={`${PUBLIC_URL}${PathPatterns.ReferralProgramMain}`} />
      </Helmet>
      <PageHeader pt={{ desktop: 20, phone: 0, tablet: 16 }} onLeftButtonPress={onLeftButtonPress}>
        {t('referralProgram.mainPage.title')}
      </PageHeader>
      <PageContent
        px={{ default: 12, desktop: 16, tablet: 16 }}
        py={{ default: 8, desktop: 20, phone: 12, tablet: 16 }}
        pb={{ desktop: isMobile ? 88 : 4, phone: 0, tablet: isMobile ? 84 : 0 }}
      >
        <FeatureReferralProgram />
      </PageContent>
    </>
  );
};
