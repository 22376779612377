import { TrustWalletConnector } from '@rehold-v3/connectkit';

import { supportedChains } from 'shared/config';

import { getImageWallet } from '../../getImages';
import { AppWallet } from '../../types';

export const trustWallet: AppWallet = {
  connector: new TrustWalletConnector({
    chains: supportedChains as any,
  }),
  iconPath: getImageWallet('trust'),
  id: 'trust',
  name: 'Trust',
};
