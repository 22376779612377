import { Event, track } from '@rehold-v3/data-layer-client';
import { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDisconnect } from 'wagmi';

import { DisconnectIcon, CopyIcon, ExploreIcon, GiftIcon } from 'shared/assets';
import { useBlockExplorerLink, useCopy, useAccount } from 'shared/hooks';
import { Box, Text } from 'shared/ui';

import styles from './styles.module.scss';
import { PathPatterns } from 'shared';

type Props = {
  onToggle: (value: boolean) => void;
};

export const WalletMenu: FC<Props> = ({ onToggle }) => {
  const { t } = useTranslation();

  const { pathname: from } = useLocation();
  const { address, connector } = useAccount();
  const { disconnectAsync } = useDisconnect();

  const copy = useCopy(address);
  const link = useBlockExplorerLink('address', address);
  const navigate = useNavigate();

  const handleCopyAddressClick = useCallback(() => {
    if (address) {
      track(Event.WALLET_ADDRESS_COPIED, { address });
      copy();
    }
    onToggle(false);
  }, [address, copy, onToggle]);

  const handleViewExplorerClick = useCallback(() => {
    track(Event.WALLET_ADDRESS_VIEW_EXPLORER_CLICKED, { address: address! });
    window.open(link?.url, '_blank')?.focus();

    onToggle(false);
  }, [address, link, onToggle]);

  const handleDisconnectClick = useCallback(async () => {
    await disconnectAsync();

    if (address && connector) track(Event.WALLET_DISCONNECTED, { address, from, provider: connector.name });

    onToggle(false);
  }, [address, connector, disconnectAsync, from, onToggle]);

  return (
    <>
      <Box
        className={styles['menu-item']}
        onClick={() => {
          navigate(PathPatterns.ReferralProgramMain);
        }}
        paddingX={16}
        paddingY={12}
      >
        <Box flexDirection="row" alignItems="center" gap="8px">
          <GiftIcon width={24} height={24} style={{ flexShrink: 0 }} />
          <Text text="app-14-medium">{t('referralProgram.mainPage.title')}</Text>
        </Box>
      </Box>
      <Box className={styles['menu-item']} onClick={handleCopyAddressClick} paddingX={16} paddingY={12}>
        <Box flexDirection="row" alignItems="center" gap="8px">
          <CopyIcon width={24} height={24} style={{ flexShrink: 0 }} />
          <Text text="app-14-medium">{t('menu.wallet.copy')}</Text>
        </Box>
      </Box>
      <Box className={styles['menu-item']} onClick={handleViewExplorerClick} paddingX={16} paddingY={12}>
        <Box flexDirection="row" alignItems="center" gap="8px">
          <ExploreIcon width={24} height={24} style={{ flexShrink: 0 }} />
          <Text text="app-14-medium">{t('common.viewOnExplorer')}</Text>
        </Box>
      </Box>
      <Box className={styles['menu-item']} onClick={handleDisconnectClick} paddingX={16} paddingY={12}>
        <Box flexDirection="row" alignItems="center" gap="8px">
          <DisconnectIcon width={24} height={24} style={{ flexShrink: 0 }} />
          <Text text="app-14-medium">{t('menu.wallet.disconnect')}</Text>
        </Box>
      </Box>
    </>
  );
};
