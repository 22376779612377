import { getImageChain } from 'entities/Chain';

import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { routerAbi } from './abi';
import { AppChain } from './types';

export const bscTestnet: AppChain<'bsc-testnet'> = {
  blockExplorers: {
    default: {
      name: 'BscScan',
      url: 'https://testnet.bscscan.com',
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  disabled: false,
  id: 97,
  logo: <CurrencyIcon url={getImageChain('bsc')} ticker="bnb" size={24} />,
  name: 'BNB Chain Testnet',
  nativeCurrency: {
    decimals: 18,
    name: 'BNB',
    symbol: 'BNB',
  },
  network: 'bsc-testnet',
  tokenStandard: 'BEP-20',
  renderLogo: (size) => <CurrencyIcon url={getImageChain('bsc')} ticker="bnb" size={size} />,
  router: {
    abi: routerAbi,
    address: '0x1cCdd5cab3b6bF35dC2038Eb8186720984a21000',
  },
  rpcUrls: {
    default: {
      http: ['https://data-seed-prebsc-2-s2.binance.org:8545'],
    },
    public: {
      http: ['https://data-seed-prebsc-2-s2.binance.org:8545'],
    },
  },
  testnet: true,
  vault: {
    address: '0xF0657F1160F7a07D65acF9474a19A5A1bF32fF6b',
  },
  tokens: {
    usdt: { address: '0x3075E033958786CfD93A95619E3B280fEcD369bA' },
    usdc: { address: '0x5c32F80319871dD2b9485322126972E5B5DEF2A4' },
  },
};
