import { useModal } from '@rehold-v3/connectkit';
import { Event, track } from '@rehold-v3/data-layer-client';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAccount } from 'shared/hooks';
import { Box, Button } from 'shared/ui';

import { PerpetualsFormBalanceSelector } from './PerpetualsFormBalanceSelector';
import { PerpetualsFormModal } from './PerpetualsFormModal';
import { PerpetualsBalanceAction } from './PerpetualsFormModal/types';
import './index.scss';

export const PerpetualsFormBalance = () => {
  const { t } = useTranslation();
  const { isConnected } = useAccount();

  const [depositOpen, setDepositOpen] = useState(false);
  const [withdrawOpen, setWithdrawOpen] = useState(false);

  const { setOpen } = useModal();

  const handleDepositClick = () => {
    if (isConnected) {
      setDepositOpen(true);
    } else {
      setOpen(true);
    }

    track(Event.PERPETUAL_OPEN_DEPOSIT_MODAL_CLICKED);
  };

  const handleWithdrawClick = () => {
    if (isConnected) {
      setWithdrawOpen(true);
    } else {
      setOpen(true);
    }

    track(Event.PERPETUAL_OPEN_WITHDRAW_MODAL_CLICKED);
  };

  return (
    <>
      <PerpetualsFormModal
        onOpen={() => setDepositOpen(true)}
        open={depositOpen}
        onClose={() => setDepositOpen(false)}
        type={PerpetualsBalanceAction.DEPOSIT}
      />
      <PerpetualsFormModal
        onOpen={() => setWithdrawOpen(true)}
        open={withdrawOpen}
        onClose={() => setWithdrawOpen(false)}
        type={PerpetualsBalanceAction.WITHDRAW}
      />
      <Box className="perpetuals-form-balance">
        <Box zIndex={1}>
          <PerpetualsFormBalanceSelector />
        </Box>
        <Box className="perpetuals-form-balance-buttons">
          <Button onClick={handleDepositClick} className="perpetuals-form-balance-button" variant="secondary">
            {t('perpetuals.form.balance.deposit')}
          </Button>
          <Button onClick={handleWithdrawClick} className="perpetuals-form-balance-button" variant="secondary-solid">
            {t('perpetuals.form.balance.withdraw')}
          </Button>
        </Box>
      </Box>
    </>
  );
};
