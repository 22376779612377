import { Event, track, TrackFunctionProperties } from '@rehold-v3/data-layer-client';
import BigNumber from 'bignumber.js';
import { useState } from 'react';
import { useBalance } from 'wagmi';

import { usePerpetualForm } from 'entities/Perpetual/lib/hooks';
import { useCreateWithdrawal } from 'entities/User/lib/useCreateWithdrawal';

import { useAccount } from 'shared/hooks';
import { UNKNOWN_ERROR } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { queryClient } from 'shared/lib/react-query';
import { waitForTransfer } from 'shared/lib/waitForTransfer';

type Props = {
  amountEth: string;
  chainId: number;
  ticker: string;
  token: string | undefined;
};

export const useBalanceWithdraw = ({ amountEth, ticker, chainId, token }: Props) => {
  const { address } = useAccount();

  const {
    state,
    balancesQuery: { refetch: refetchTradingBalance },
  } = usePerpetualForm();

  const { refetch: refetchTokenBalance } = useBalance({ address, token: token as `0x${string}`, enabled: !!token });
  const { createWithdrawal } = useCreateWithdrawal({ amount: amountEth, chainId, ticker });

  const [isTransactionLoading, setIsTransactionLoading] = useState(false);

  const isSufficientFunds = state.balance && BigNumber(state.balance.amount.toString()).gte(amountEth || 0);

  const transfer = async (trackParams: TrackFunctionProperties<Event.PERPETUAL_WITHDRAW_ATTEMPTED>) => {
    try {
      if (!address) throw new Error('Account is not connected for deposit');

      track(Event.PERPETUAL_WITHDRAW_ATTEMPTED, trackParams);

      setIsTransactionLoading(true);

      const transactionResult = await createWithdrawal?.();

      if (!transactionResult?.data) return;

      await waitForTransfer({
        address,
        chainId,
        txHash: transactionResult.data.txHash,
        type: 'WITHDRAWAL',
        amount: amountEth,
        ticker: ticker.toUpperCase(),
      });

      queryClient.invalidateQueries([`perpetual-balance-${address}`]);

      refetchTradingBalance();
      refetchTokenBalance();
    } catch (error: any) {
      track(Event.PERPETUAL_WITHDRAW_FAILED, { ...trackParams, error: error.message ?? UNKNOWN_ERROR });

      logger.error(error);
    } finally {
      setIsTransactionLoading(false);
    }
  };

  return {
    isLoading: isTransactionLoading,
    isPreLoading: !state.balance,
    isSufficientFunds,
    withdraw: transfer,
  };
};
