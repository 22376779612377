import { useMediaQuery } from '@mui/material';
import { formatAmount } from '@rehold-v3/formatters';
import BigNumber from 'bignumber.js';
import { ReactElement, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { usePerpetualTariffs } from 'entities/Perpetual/lib';
import { PerpetualClosedPosition, PerpetualOpenedPosition } from 'entities/Perpetual/model';
import { useRateInterval } from 'entities/Rates';
import { RateProduct } from 'entities/Rates/model/types';

import { formatPercent } from 'shared/lib';
import { Box, Text, Tooltip } from 'shared/ui';

import './index.scss';

export const PerpetualsNetValueTooltip: React.CFC<{
  position: PerpetualOpenedPosition | PerpetualClosedPosition;
}> = ({ position, children }) => {
  const { t } = useTranslation();

  const [tooltipOpen, setTooltipOpen] = useState(false);

  const isOpen = position.status === 'OPEN';

  const rate = useRateInterval({
    from: position.baseTicker,
    to: position.quoteTicker,
    product: RateProduct.PERPETUAL,
    skip: !isOpen,
  });
  const quoteOutputRate = useRateInterval({
    from: position.quoteTicker,
    to: position.inputTicker,
    product: RateProduct.DEFAULT,
    skip: !isOpen,
  });

  const { tariffs } = usePerpetualTariffs();
  const tariff = useMemo(
    () =>
      tariffs.find(
        (t) =>
          t.baseTicker === position.baseTicker &&
          t.quoteTicker === position.quoteTicker &&
          t.isShort === position.isShort,
      ),
    [tariffs],
  );

  const amount = BigNumber(position.inputAmount).minus(position.openingFeeAmount ?? 0);

  const closedPriceRate = position.isShort ? rate.ask : rate.bid;
  const closedPrice = isOpen ? closedPriceRate : position.closedPrice;

  const closingFeeAmountOutput = BigNumber(position.closingFeeAmount ?? 0);
  const closingFeeAmountQuote = BigNumber(position.collateralAmount)
    .times(position.isShort ? 1 : closedPrice)
    .times(tariff?.closingFee ?? 0);
  const closingFeeAmount = isOpen ? closingFeeAmountQuote.multipliedBy(quoteOutputRate.price) : closingFeeAmountOutput;

  const quoteOutputPrice = isOpen ? quoteOutputRate.price : BigNumber(position.quoteOutputRate ?? 1);

  const outputAmountOpened = (
    position.isShort
      ? BigNumber(position.collateralAmount).minus(BigNumber(position.overdraftAmount).times(closedPrice))
      : BigNumber(position.collateralAmount).multipliedBy(closedPrice).minus(position.overdraftAmount)
  )
    .multipliedBy(quoteOutputRate.price)
    .minus(closingFeeAmount);
  const outputAmount = isOpen ? outputAmountOpened : position.outputAmount;

  const borrowFee = BigNumber(position.overdraftAmount)
    .minus(position.borrowedAmount)
    .multipliedBy(position.isShort ? position.initialPrice : 1)
    .multipliedBy(quoteOutputPrice);
  const outputAmountNoFees = (
    position.isShort
      ? BigNumber(position.collateralAmount).minus(BigNumber(position.borrowedAmount).times(closedPrice))
      : BigNumber(position.collateralAmount).multipliedBy(closedPrice).minus(position.borrowedAmount)
  ).multipliedBy(quoteOutputPrice);
  const profit = outputAmountNoFees.minus(amount);

  const profitAfterFees = BigNumber(outputAmount).minus(amount);
  const profitAfterFeesPercent = profitAfterFees.div(amount);

  const isFullLoss = profitAfterFeesPercent.lte(-1);

  const isSmallScreen = useMediaQuery('(max-width: 360px)');
  const rowGap = isSmallScreen ? 12 : 24;

  const handleScroll = () => {
    setTooltipOpen(false);
  };
  const handleTooltipOpen = () => {
    setTooltipOpen(true);
  };
  const handleTooltipClose = () => {
    setTooltipOpen(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('touchmove', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('touchmove', handleScroll);
    };
  }, []);

  return (
    <Tooltip
      PopperProps={{ style: { zIndex: '2' } }}
      open={tooltipOpen}
      onOpen={handleTooltipOpen}
      onClose={handleTooltipClose}
      leaveTouchDelay={60000}
      title={
        <Box>
          <Box flexDirection="row" justifyContent="space-between" gap={rowGap}>
            <Text whiteSpace="nowrap">{t('perpetuals.form.tooltip.pnl.amount')}</Text>
            <Text whiteSpace="nowrap">
              {formatAmount({
                value: amount,
                symbol: position.inputTicker,
                roundingMode: BigNumber.ROUND_DOWN,
              })}{' '}
              {position.inputTicker.toUpperCase()}
            </Text>
          </Box>
          <Box flexDirection="row" justifyContent="space-between" gap={rowGap}>
            <Text whiteSpace="nowrap">{t('perpetuals.form.tooltip.pnl.profit')}</Text>
            <Text whiteSpace="nowrap" style={{ color: isFullLoss ? 'var(--red-01)' : getStatColor(profit) }}>
              {!isFullLoss && profit.gt(0) ? '+' : ''}
              {formatAmount({
                value: isFullLoss ? amount.multipliedBy(-1) : profit,
                symbol: position.inputTicker,
                roundingMode: BigNumber.ROUND_DOWN,
              })}{' '}
              {position.inputTicker.toUpperCase()}
            </Text>
          </Box>
          <Box flexDirection="row" justifyContent="space-between" gap={rowGap}>
            <Text whiteSpace="nowrap">{t('perpetuals.form.tooltip.pnl.borrowFee')}</Text>
            <Text
              whiteSpace="nowrap"
              style={{ color: isFullLoss ? undefined : getStatColor(borrowFee.multipliedBy(-1)) }}
            >
              {!isFullLoss && borrowFee.gt(0) && '-'}
              {formatAmount({
                value: isFullLoss ? 0 : borrowFee,
                symbol: position.inputTicker,
                roundingMode: BigNumber.ROUND_UP,
              })}{' '}
              {position.inputTicker.toUpperCase()}
            </Text>
          </Box>
          <Box flexDirection="row" justifyContent="space-between" gap={rowGap}>
            <Text whiteSpace="nowrap">{t('perpetuals.form.tooltip.pnl.closeFee')}</Text>
            <Text
              whiteSpace="nowrap"
              style={{ color: isFullLoss ? undefined : getStatColor(closingFeeAmount.multipliedBy(-1)) }}
            >
              {!isFullLoss && closingFeeAmount.gt(0) && '-'}
              {formatAmount({
                value: isFullLoss ? 0 : closingFeeAmount,
                symbol: position.inputTicker,
                roundingMode: BigNumber.ROUND_UP,
              })}{' '}
              {position.inputTicker.toUpperCase()}
            </Text>
          </Box>
          <Box flexDirection="row" justifyContent="space-between" gap={rowGap} mt={12}>
            <Text whiteSpace="nowrap">{t('perpetuals.form.tooltip.pnl.profitAfterFees')}</Text>
            <Text style={{ color: getStatColor(profitAfterFees) }} whiteSpace="nowrap">
              {profitAfterFees.gt(0) ? '+' : ''}
              {formatAmount({
                value: profitAfterFees,
                symbol: position.inputTicker,
                roundingMode: BigNumber.ROUND_DOWN,
              })}{' '}
              {position.inputTicker.toUpperCase()}
            </Text>
          </Box>
          <Box flexDirection="row" justifyContent="space-between" gap={rowGap}>
            <Text whiteSpace="nowrap" />
            <Text style={{ color: getStatColor(profitAfterFees) }} whiteSpace="nowrap">
              ({profitAfterFees.gt(0) ? '+' : ''}
              {formatPercent({ value: profitAfterFeesPercent })}%)
            </Text>
          </Box>
        </Box>
      }
    >
      <Box className="perpetuals-netvalue-tooltip-handle">{children as ReactElement<any, any>}</Box>
    </Tooltip>
  );
};

const getStatColor = (number: BigNumber) => {
  if (number.gt(0)) return '#00a556';
  if (number.lt(0)) return 'var(--red-01)';
  return 'white';
};
