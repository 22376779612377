import { useMediaQuery } from '@mui/material';
import { Event, track } from '@rehold-v3/data-layer-client';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useOnrampTokens } from 'entities/Onramp/lib/hooks';
import { OnrampCryptoCurrency } from 'entities/Onramp/model';

import { Box, Modal, PageContent, Text } from 'shared/ui';

import { OnrampAsset } from '../OnrampAsset';

import styles from './OnrampAssetSelect.module.scss';
import { OnrampAssetSelectModalFilter } from './OnrampAssetSelectModalFilter';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onSelect: (selected: OnrampCryptoCurrency) => void;
  selected: OnrampCryptoCurrency | null;
}

export const OnrampAssetSelectModal: FC<Props> = observer(({ selected, isOpen, onClose, onSelect }) => {
  const { t } = useTranslation();

  const { tokensCrypto: tokens, queryCrypto: queryData } = useOnrampTokens();

  const [filteredAssets, setFilteredAssets] = useState<OnrampCryptoCurrency[]>(tokens);

  const [searchText, setSearchText] = useState('');
  const [debouncedText, setDebouncedText] = useState('');

  useEffect(() => {
    if (!queryData.isLoading && tokens && tokens.length !== 0) {
      const filter = () => {
        if (!debouncedText) {
          return tokens;
        }

        return tokens.filter((token) => {
          const text = debouncedText.toLowerCase();
          const escapedInput = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
          const inputRegexp = new RegExp(`[a-zA-Z_]*${escapedInput}[a-zA-Z_]*`, 'i');

          const isMatchSearchText = text ? inputRegexp.test(`${token.code}${token.name}`) : true;

          return isMatchSearchText;
        });
      };

      setFilteredAssets(filter());
    }
  }, [tokens, queryData, debouncedText]);

  useEffect(() => {
    if (!isOpen) {
      setSearchText('');
      setDebouncedText('');
    }
  }, [isOpen]);

  const handleSelect = (selected: OnrampCryptoCurrency) => {
    track(Event.ONRAMP_TOKENS_SELECTED, { ticker: selected.code });
    onSelect(selected);
  };

  const handleClose = () => {
    onClose();
  };

  const isWideMobile = useMediaQuery('(max-width: 575px)', { noSsr: true });

  return (
    <Modal
      className={styles.modal}
      isOpen={isOpen}
      onClose={handleClose}
      maxWidth={isWideMobile ? '100%' : 500}
      stickyTop
      closeIcon
      header={
        <Box height={24} px={22}>
          <Text text="app-18-medium" style={{ position: 'absolute', bottom: 0 }}>
            {t('modals.onramp.assetSelection.title')}
          </Text>
        </Box>
      }
    >
      <PageContent className="hide-horizontal-scroll" height="100%" maxHeight="100%" flexShrink={2} px={22}>
        <OnrampAssetSelectModalFilter
          onClose={handleClose}
          onSearch={setSearchText}
          setDebouncedText={setDebouncedText}
          searchText={searchText}
        />
        <Box flexDirection="row" mb={2} mt={2}>
          <div className={`${styles.tickers} hide-scroll`} />
        </Box>
        {!queryData.isLoading && (
          <ul className={`${styles.assets} hide-scroll`}>
            {filteredAssets.map((currency) => (
              <OnrampAsset
                key={`onramp-asset-index-${currency.code}`}
                currency={currency}
                onPress={(selected) => handleSelect(selected)}
                className={styles.item}
                selected={selected?.code === currency.code}
              />
            ))}
            {!filteredAssets.length && (
              <div className={styles.noResults}>
                <Text text="app-16-medium" sx={{ opacity: 0.4 }}>
                  {t('common.noResults')}
                </Text>
              </div>
            )}
          </ul>
        )}
      </PageContent>
    </Modal>
  );
});
