import { Skeleton } from '@mui/material';
import { Event, track } from '@rehold-v3/data-layer-client';
import { formatPrice } from '@rehold-v3/formatters';
import classnames from 'classnames';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';

import { usePerpetualTariffs } from 'entities/Perpetual/lib/hooks';
import { usePerpetualForm } from 'entities/Perpetual/lib/hooks/usePerpetualForm';
import { useRateExtended, useRateInterval } from 'entities/Rates';
import { RateProduct } from 'entities/Rates/model/types';

import { MarginDownIcon, MarginUpIcon } from 'shared/assets';
import { formatWithCommas } from 'shared/lib/formats/format';
import { Box, Text } from 'shared/ui';

import './index.scss';

export const PerpetualsFormSwitch = () => {
  const { t } = useTranslation();

  const { state, changeTariff } = usePerpetualForm();

  const { tariffs } = usePerpetualTariffs();

  const rate = useRateInterval({
    from: state.tariff?.baseTicker,
    to: state.tariff?.quoteTicker,
    product: RateProduct.PERPETUAL,
  });

  const { data: rateExtendedData } = useRateExtended({
    symbol: state.tariff ? `${state.tariff.baseTicker}/${state.tariff.quoteTicker}` : null,
    product: RateProduct.PERPETUAL,
  });

  const bid = rate.bid ?? rateExtendedData?.[0]?.bid;
  const ask = rate.ask ?? rateExtendedData?.[0]?.ask;

  const switchTariff = useMemo(
    () =>
      tariffs.find(
        (t) =>
          t.baseTicker === state.tariff?.baseTicker &&
          t.quoteTicker === state.tariff.quoteTicker &&
          t.isShort !== state.tariff.isShort,
      ),
    [tariffs, state.tariff],
  );

  const handleLongClick = () => {
    if (!state.isShort) {
      return;
    }

    if (switchTariff) {
      changeTariff(switchTariff.id);
    }

    track(Event.PERPETUAL_DIRECTION_CLICKED, { direction: 'long' });
  };

  const handleShortClick = () => {
    if (state.isShort) {
      return;
    }

    if (switchTariff) {
      changeTariff(switchTariff.id);
    }

    track(Event.PERPETUAL_DIRECTION_CLICKED, { direction: 'short' });
  };

  return (
    <Box className="perpetuals-form-switch">
      <Box
        className={classnames('perpetuals-form-switch-button', { active: !state.isShort })}
        onClick={handleLongClick}
      >
        <Box className="perpetuals-form-switch-button-markup">
          <MarginUpIcon />
          {t('perpetuals.form.switch.long')}
        </Box>
        {state.tariff && (rate.ready || rateExtendedData) && (
          <Text text="app-12-regular" opacity={0.7}>
            {formatWithCommas(
              formatPrice({
                from: state.tariff.baseTicker,
                to: state.tariff.quoteTicker,
                value: ask,
              }),
            )}
          </Text>
        )}
        {(!state.tariff || !(rate.ready || rateExtendedData)) && (
          <Skeleton
            width={72}
            height={15}
            sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 1)' }}
            animation="wave"
          />
        )}
      </Box>
      <Box
        className={classnames('perpetuals-form-switch-button', { active: state.isShort })}
        onClick={handleShortClick}
      >
        <Box className="perpetuals-form-switch-button-markup">
          {t('perpetuals.form.switch.short')}
          <MarginDownIcon />
        </Box>
        {state.tariff && (rate.ready || rateExtendedData) && (
          <Text text="app-12-regular" opacity={0.7}>
            {formatWithCommas(
              formatPrice({
                from: state.tariff.baseTicker,
                to: state.tariff.quoteTicker,
                value: bid,
              }),
            )}
          </Text>
        )}
        {(!state.tariff || !(rate.ready || rateExtendedData)) && (
          <Skeleton
            width={72}
            height={15}
            sx={{ bgcolor: 'rgba(134, 77, 247, 0.4)', borderRadius: '4px', transform: 'scale(1, 1)' }}
            animation="wave"
          />
        )}
      </Box>
    </Box>
  );
};
