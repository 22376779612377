import { Event, track } from '@rehold-v3/data-layer-client';
import { observer } from 'mobx-react-lite';
import React, { useMemo, FC } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';

import { useStakingPlans, DualStakingPlanSelect } from 'entities/Dual';

import { Box } from 'shared/ui';

interface StakingPlanSelectFieldProps {
  className?: string;
  name: string;
}

export const StakingPlanSelectField: FC<StakingPlanSelectFieldProps> = observer(({ className }) => {
  const { control } = useFormContext();
  const [assetId, chainId] = useWatch({ control, name: ['assetId', 'chainId'] });

  const plans = useStakingPlans({ assetId, chainId });

  const items = useMemo(
    () =>
      plans?.map((p) => ({
        apr: p.apr,
        hours: p.stakingPeriod,
      })) || [],
    [plans],
  );

  if (!plans || !plans.length) {
    return <Box className={className} />;
  }

  return (
    <Controller
      control={control}
      name="tariff"
      render={({ field: { onChange, value } }) => {
        const selectedIndex = plans.findIndex((i) => i.id === value?.id);
        const handleStakingPlanChange = (v: number) => {
          const nextValue = plans[v];
          track(Event.DUAL_STAKING_PLAN_SELECTED, {
            apr: nextValue.apr,
            stakingPeriod: nextValue.stakingPeriod,
          });
          onChange(nextValue);
        };

        return (
          <DualStakingPlanSelect
            items={items}
            selected={selectedIndex}
            onSelect={handleStakingPlanChange}
            className={className}
          />
        );
      }}
    />
  );
});
