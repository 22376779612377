import { getAmountPrecisionBySymbol } from '@rehold-v3/formatters';
import BigNumber from 'bignumber.js';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Address, formatUnits } from 'viem';

import { SwapDataResponse } from 'entities/Swap';
import { useSwapTokenByAddress } from 'entities/Swap/lib';
import { SwapTokenIcon } from 'entities/Token';

import { BackIcon, CheckIcon } from 'shared/assets';
import { useNetwork } from 'shared/hooks/network';
import theme from 'shared/theme';
import { Box, Modal, PageContent, Text } from 'shared/ui';

import styles from './SwapStateModal.module.scss';

interface Props {
  data?: SwapDataResponse;
  hash: string;
  onClose: () => void;
  open: boolean;
}

export const SwapStateModalSubmitted: FC<Props> = ({ data, hash, open, onClose }) => {
  const { t } = useTranslation();
  const { network } = useNetwork();
  const { data: fromTokenData } = useSwapTokenByAddress(data?.fromToken as Address);
  const { data: toTokenData } = useSwapTokenByAddress(data?.toToken as Address);

  return (
    <Modal className={styles.modal} isOpen={open && !!data} onClose={onClose} closeIcon>
      <PageContent height="100%" maxHeight="100%" flexShrink={2} px={22} pb={56} pt={34}>
        {data && (
          <Box flexDirection="column" alignItems="center" justifyContent="center" gap={16}>
            <Box
              height={60}
              width={60}
              flexDirection="column"
              alignItems="center"
              justifyContent="center"
              backgroundColor="primary-01"
              borderRadius={60}
            >
              <CheckIcon color={theme.colors['secondary-02']} width={40} height={40} />
            </Box>
            <Text text="app-22-medium">{t('modals.swapState.submitted.title')}</Text>
            <Box flexDirection="row" alignItems="center" justifyContent="center" gap={10}>
              {fromTokenData && (
                <>
                  <SwapTokenIcon size={20} url={fromTokenData.logoURI} address={data.fromToken as Address} />
                  <Text>{`${BigNumber(formatUnits(BigInt(data.fromAmount), fromTokenData.decimals)).toFixed(
                    getAmountPrecisionBySymbol(fromTokenData.symbol.toLowerCase()),
                  )} ${fromTokenData.symbol}`}</Text>
                </>
              )}
              <BackIcon
                width={20}
                height={20}
                style={{ transform: 'rotate(180deg)' }}
                color={theme.colors['gray-01']}
              />
              {toTokenData && (
                <>
                  <SwapTokenIcon size={20} url={toTokenData.logoURI} address={data.toToken as Address} />
                  <Text>{`${BigNumber(formatUnits(BigInt(data.toAmount), toTokenData.decimals)).toFixed(
                    getAmountPrecisionBySymbol(toTokenData.symbol.toLowerCase()),
                  )} ${toTokenData.symbol}`}</Text>
                </>
              )}
            </Box>
            {network && network.blockExplorers && network.blockExplorers.default && (
              <Link to={`${network.blockExplorers.default.url}/tx/${hash}`} target="_blank">
                <Text text="app-14-medium" color="secondary-03">
                  {t('common.viewOnExplorer')}
                </Text>
              </Link>
            )}
          </Box>
        )}
      </PageContent>
    </Modal>
  );
};
