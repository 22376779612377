import { useMediaQuery } from '@mui/material';
import { Event, track } from '@rehold-v3/data-layer-client';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { memo, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { storageSignature } from 'features/Auth';

import { getOnrampData, useOnrampForm, useOnrampQuote } from 'entities/Onramp';

import { useAccount } from 'shared/hooks';
import { Button } from 'shared/ui';

import styles from './SubmitButton.module.scss';

type SubmitProps = {
  onSubmit: () => void;
  onSubmitFail: () => void;
  onSubmitSuccess: (url: string) => void;
};

export const OnrampSubmitButton: React.FC<SubmitProps> = memo((props) => {
  const [resetForm, setResetForm] = useState(false);

  return (
    <>
      <OnrampInnerSubmitButton {...props} resetState={resetForm ? () => setResetForm(false) : undefined} />
    </>
  );
});

type SubmitInnerProps = {
  resetState?: () => void;
} & SubmitProps;

export const OnrampInnerSubmitButton: React.FC<SubmitInnerProps> = observer(
  ({ onSubmit, onSubmitFail, onSubmitSuccess, resetState }) => {
    const {
      t,
      i18n: { language },
    } = useTranslation();

    const { address } = useAccount();
    const signature = storageSignature.get(address!) || undefined;

    const {
      formState: { isSubmitting },
      reset: resetForm,
      handleSubmit,
    } = useFormContext();

    const { output, formValues } = useOnrampForm();

    const { data: quoteData, isFetching, isInitialLoading, isError } = useOnrampQuote();

    useEffect(() => {
      if (resetState) {
        resetForm(formValues, { keepValues: true, keepDefaultValues: true, keepTouched: true, keepIsSubmitted: false });
        resetState();
      }
    }, [formValues, resetState, resetForm]);

    const submit = async () => {
      onSubmit();

      try {
        const result = await getOnrampData({
          signature,
          address,
          color: '#864DF7',
          fromAmount: Number(formValues.fromAmount),
          fromTokenCode: formValues.fromTokenCode,
          language: language || 'en',
          provider: formValues.provider,
          redirectURL: window.location.href,
          toTokenCode: formValues.toTokenCode,
        });

        onSubmitSuccess(result.url);
      } catch (e: any) {
        onSubmitFail();
      }
    };

    const onSubmitPress = () => {
      track(Event.ONRAMP_START_CLICKED, {
        address: address as string,
        fromAmount: formValues.fromAmount,
        fromTicker: formValues.fromTokenCode,
        toAmount: quoteData!.toAmount,
        toTicker: formValues.toTokenCode,
      });
      handleSubmit(submit)();
    };

    const isSmallMobile = useMediaQuery('(max-height: 715px) and (max-width: 767px)');

    const isFiatProvided = !!formValues.fromTokenCode;
    const isCryptoProvided = !!formValues.toTokenCode;
    const isAmountProvided = !!formValues.fromAmount;
    const isProviderSelected = !!formValues.provider;

    const disabled =
      !isFiatProvided ||
      !isCryptoProvided ||
      !isAmountProvided ||
      !isProviderSelected ||
      (isFetching && isInitialLoading) ||
      isError;

    const button = useMemo(
      () => (
        <>
          <div className={classNames(styles.container)}>
            {!isFiatProvided || !isCryptoProvided ? (
              <Button mt={3} disabled variant="secondary">
                {t('onramp.button.submit.selectCurrency')}
              </Button>
            ) : !isAmountProvided || !BigNumber(formValues.fromAmount).gt(0) ? (
              <Button mt={3} disabled loading={false} variant="secondary" data-id="swap-zero-amount">
                {t('onramp.button.submit.enterAmount')}
              </Button>
            ) : (
              <Button
                mt={3}
                className={classNames({
                  [styles['pulse-light']]: !disabled,
                })}
                variant="primary"
                onClick={onSubmitPress}
                disabled={disabled}
                loading={isSubmitting}
                data-id="start-swap"
              >
                {t('onramp.button.submit.buy', { ticker: output.currency?.ticker.toUpperCase() })}
              </Button>
            )}
          </div>
        </>
      ),
      [isSubmitting, onSubmitPress],
    );

    if (isSmallMobile) return ReactDOM.createPortal(button, document.body);

    return button;
  },
);
