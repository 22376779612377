import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { routerAbi } from './abi';
import { AppChain } from './types';

export const polygon: AppChain<'polygon'> = {
  blockExplorers: {
    default: {
      name: 'PolygonScan',
      url: 'https://polygonscan.com',
    },
  },
  contracts: {
    multicall3: {
      address: '0xcA11bde05977b3631167028862bE2a173976CA11',
    },
  },
  disabled: false,
  domain: {
    address: '0xa9a6A3626993D487d2Dbda3173cf58cA1a9D9e9f',
  },
  id: 137,
  logo: <CurrencyIcon ticker="matic" size={24} />,
  name: 'Polygon',
  nativeCurrency: {
    decimals: 18,
    name: 'MATIC',
    symbol: 'MATIC',
  },
  network: 'polygon',
  tokenStandard: 'ERC-20',
  renderLogo: (size) => <CurrencyIcon ticker="matic" size={size} />,
  router: {
    abi: routerAbi,
    address: '0x1D3a30c894F581F0b7295E1bdBED268818318c13',
  },
  rpcUrls: {
    default: {
      http: ['https://rpc.ankr.com/polygon'],
    },
    public: {
      http: ['https://rpc.ankr.com/polygon'],
    },
  },
  vault: {
    address: '0xF09c6F088057A2EB11AEF885640F0440F3710f8b',
  },
};
