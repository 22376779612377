import { Event, track, TrackFunctionProperties } from '@rehold-v3/data-layer-client';
import { useCallback } from 'react';

import { useSignAuthMessage } from 'features/Auth';

import { useClosePerpetualPositionMutation } from 'entities/Perpetual/model/hooks';

import { DEFAULT_ERROR, UNKNOWN_ERROR } from 'shared/lib';
import { notifyError } from 'shared/lib/notifications';

const ERROR_POSITION_NOT_FOUND = 'Position not found';

export const usePerpetualClosePosition = ({ id, onSuccess }: { id: string; onSuccess: () => void }) => {
  const { mutateAsync, ...rest } = useClosePerpetualPositionMutation();

  const { getSignature } = useSignAuthMessage();

  const closePosition = useCallback(
    async (trackParams: TrackFunctionProperties<Event.PERPETUAL_CLOSE_ATTEMPTED>) => {
      try {
        const signature = await getSignature();

        if (!signature) return;

        track(Event.PERPETUAL_CLOSE_ATTEMPTED, trackParams);

        await mutateAsync({
          id,
          signature,
        });

        track(Event.PERPETUAL_CLOSE_SUCCEEDED, trackParams);

        onSuccess();
      } catch (e: any) {
        const errorMessage = e?.response?.data?._error?.message;

        track(Event.PERPETUAL_CLOSE_FAILED, { ...trackParams, error: errorMessage ?? e.message ?? UNKNOWN_ERROR });

        if (errorMessage === ERROR_POSITION_NOT_FOUND) {
          notifyError({ text: 'Position not found' });
          return;
        }

        notifyError({ text: DEFAULT_ERROR });
      }
    },
    [mutateAsync, getSignature],
  );

  return { closePosition, ...rest };
};
