import { formatAmount } from '@rehold-v3/formatters';
import BigNumber from 'bignumber.js';
import { Toast } from 'react-hot-toast';
import { useTranslation } from 'react-i18next';

import { CheckIcon, ErrorIcon, LanguageIcon } from 'shared/assets';
import { useBlockExplorerLink } from 'shared/hooks/useBlockExplorerLink';
import { Box } from 'shared/ui/Box';
import { Spinner } from 'shared/ui/Spinner';
import { Text } from 'shared/ui/Text';

import { BasicToast } from '../BasicToast';

interface TransferToastProps {
  amount: string;
  chainId?: number;
  onClose?: VoidFunction;
  status?: 'error' | 'success' | 'waiting';
  ticker: string;
  toast?: Toast;
  txHash?: string;
  type: 'DEPOSIT' | 'WITHDRAWAL';
}

export const TransferToast: React.FC<TransferToastProps> = ({
  onClose,
  status = 'waiting',
  toast,
  txHash,
  chainId,
  type,
  amount,
  ticker,
}) => {
  const { t } = useTranslation();

  const toastTitle = t(`modals.transfer.${type.toLowerCase()}.title.${status}`, {
    ticker,
    amount: formatAmount({ value: amount, symbol: ticker.toLowerCase(), roundingMode: BigNumber.ROUND_DOWN }).replace(
      '.00',
      '',
    ),
    defaultValue: '',
  });
  const textColor = status === 'error' ? 'red-01' : status === 'success' ? 'primary-01' : 'white-01';

  const link = useBlockExplorerLink('tx', txHash, chainId);

  return (
    <BasicToast onClose={onClose} toastProps={toast}>
      <Box flexDirection="row" alignItems="center">
        <Box width={24} height={24} justifyContent="center" alignItems="center" mr={8}>
          {(status === 'error' || status === 'success') && (
            <Text color={textColor}>
              {status === 'error' && <ErrorIcon width={24} height={24} />}
              {status === 'success' && <CheckIcon width={24} height={24} />}
            </Text>
          )}
          {status === 'waiting' && <Spinner size={24} />}
        </Box>
        <Box width="100%">
          <Text color={textColor}>{toastTitle}</Text>
        </Box>
      </Box>

      {link && (
        <Box borderColor="secondary-04" marginTop={16} paddingTop={16} sx={{ borderTopWidth: 1 }}>
          <a href={link.url} target="_blank" rel="noreferrer">
            <Box flexDirection="row" alignItems="center">
              <Box mr={8}>
                <Text text="app-12-regular" color="secondary-03">
                  <LanguageIcon width={16} height={16} />
                </Text>
              </Box>
              <Box flexGrow={0} flexShrink={1}>
                <Text text="app-12-regular" color="secondary-03">
                  <span
                    style={{ display: 'block', overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}
                  >
                    {t('modals.txConfirmation.viewOn', { where: `${link.anchor}: ${txHash}` })}
                  </span>
                </Text>
              </Box>
            </Box>
          </a>
        </Box>
      )}
    </BasicToast>
  );
};
