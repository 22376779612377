import { Event, track } from '@rehold-v3/data-layer-client';
import { useQueryClient } from '@tanstack/react-query';
import { observer } from 'mobx-react-lite';
import React, { useState, FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useSignAuthMessage } from 'features/Auth/lib';
import { LimitDual, useDeleteLimitDualMutation } from 'features/LimitsDual';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { logger } from 'shared/lib/logger';
import { Button, Modal, PageHeader } from 'shared/ui';

import { StyledPageContent } from './styled';

type Props = {
  dual: LimitDual;
};

export const CancelButton: FC<Props> = observer(({ dual }) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const { chainId } = useNetwork();
  const { address } = useAccount();
  const queryClient = useQueryClient();

  const { isLoading, mutateAsync: cancelLimitDual } = useDeleteLimitDualMutation();

  const trackParams = {
    address: address as string,
    apr: dual.apr,
    baseTicker: dual.baseTicker,
    direction: dual.targetPrice > dual.initialPrice ? 'up' : 'down',
    initialPrice: dual.initialPrice,
    inputAmount: dual.inputAmount,
    inputTicker: dual.inputTicker,
    quoteTicker: dual.quoteTicker,
    stakingPeriod: dual.stakingPeriod,
    targetPrice: dual.targetPrice,
  } as const;

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);

    track(Event.LIMIT_DUAL_CANCEL_CLICKED, trackParams);
  };

  const { getSignature } = useSignAuthMessage();

  const handleCancelDual = async () => {
    const signature = await getSignature();
    if (!signature) {
      return logger.error(new Error('not signature'));
    }
    if (!address) {
      return logger.error(new Error('not address'));
    }

    try {
      track(Event.LIMIT_DUAL_CANCEL_ATTEMPTED, trackParams);

      await cancelLimitDual({ address, chainId: chainId!, id: dual.id, signature });

      track(Event.LIMIT_DUAL_CANCEL_SUCCEEDED, trackParams);

      queryClient.invalidateQueries({
        queryKey: [`opened-limit-${address}-${chainId}`],
      });
      queryClient.invalidateQueries({
        queryKey: [`count-duals-${chainId}-${address}`],
      });

      closeModal();
    } catch (error: any) {
      logger.error(error);
      track(Event.LIMIT_DUAL_CANCEL_FAILED, { ...trackParams, error: error?.message ?? 'Unknown error' });
    }
  };

  return (
    <>
      <Button variant="red" onClick={openModal}>
        {t('common.cancel')}
      </Button>
      <Modal
        isOpen={isOpen}
        onClose={closeModal}
        closeIcon
        header={<PageHeader showLeftButton={false}>{t('dual.cancelLimitDual')}</PageHeader>}
      >
        <StyledPageContent px={24} pb={24} mt={24} gap={16} flexDirection="row">
          <Button variant="secondary-solid" onClick={handleCancelDual} loading={isLoading}>
            {t('common.yes')}
          </Button>
          <Button variant="secondary" onClick={closeModal}>
            {t('common.no')}
          </Button>
        </StyledPageContent>
      </Modal>
    </>
  );
});
