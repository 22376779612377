import { Event, track } from '@rehold-v3/data-layer-client';
import { RefAttributes, forwardRef, useMemo } from 'react';

import { usePerpetualForm } from 'entities/Perpetual/lib/hooks';

import { CheckIcon } from 'shared/assets';
import { useNetwork } from 'shared/hooks/network';
import { Box, Text } from 'shared/ui';

import { PerpetualsBalanceAction } from '../types';

import './index.scss';

type Props = {
  onSelect: (chain: number, ticker: string) => void;
  selected: number;
  type: PerpetualsBalanceAction;
};

export const PerpetualsTokenSelectModal: React.ForwardRefExoticComponent<Props & RefAttributes<unknown>> = forwardRef(
  ({ onSelect, selected, type }) => {
    const { state } = usePerpetualForm();

    const { networks: allNetworks } = useNetwork();

    const networks = useMemo(() => allNetworks.filter((net) => !!net.tokens), [allNetworks]);

    const tokens = useMemo(
      () =>
        networks
          .flatMap((network) =>
            Object.keys(network.tokens || {}).map((ticker) => ({ ...network.tokens?.[ticker], ticker, network })),
          )
          .sort((a, b) => b.ticker.localeCompare(a.ticker)),
      [networks],
    );

    const handleSelect = (chain: number, ticker: string) => () => {
      onSelect(chain, ticker);

      if (type === PerpetualsBalanceAction.DEPOSIT) {
        track(Event.PERPETUAL_DEPOSIT_MODAL_ASSET_SELECTED, { chainId: chain, ticker });
      }
      if (type === PerpetualsBalanceAction.WITHDRAW) {
        track(Event.PERPETUAL_WITHDRAW_MODAL_ASSET_SELECTED, { chainId: chain, ticker });
      }
    };

    return (
      <Box className="perpetuals-modal-token-select">
        {tokens.map((token) => (
          <>
            <Box onClick={handleSelect(token.network.id, token.ticker)} className="perpetuals-modal-token-select-token">
              <Box className="perpetuals-modal-token-select-token-info">
                <Box className="perpetuals-modal-token-select-token-image">
                  <img alt={`${token.ticker} token`} src={`/static/tickers/${token.ticker}.svg`} />
                  {token.network.renderLogo(16)}
                </Box>
                <Text
                  text="app-16-medium"
                  color={
                    token.network.id === selected && token.ticker === state.inputTicker ? 'primary-01' : 'white-01'
                  }
                >
                  {token.ticker.toUpperCase()}
                </Text>
                <Box className="perpetuals-modal-token-select-token-badge">
                  <Text text="app-12-medium">{token.network.tokenStandard}</Text>
                </Box>
                {token.network.testnet && (
                  <Box className="perpetuals-modal-token-select-token-badge">
                    <Text text="app-12-medium">Testnet</Text>
                  </Box>
                )}
              </Box>
              {token.network.id === selected && token.ticker === state.inputTicker && (
                <CheckIcon width={16} height={16} color="var(--primary-01)" />
              )}
            </Box>
          </>
        ))}
      </Box>
    );
  },
);
