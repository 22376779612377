import { Tooltip } from '@mui/material';
import { Event, track } from '@rehold-v3/data-layer-client';
import React from 'react';

import styles from './index.module.css';

interface TooltipProps {
  content: string;
  isPermit: boolean;
}

export const ApproveTooltip: React.FC<TooltipProps> = React.memo(({ content, isPermit }) => {
  const onOpenChange = () => {
    if (isPermit) track(Event.DUAL_PERMIT_TOOLTIP_SHOWED);
    else track(Event.DUAL_APPROVE_TOOLTIP_SHOWED);
  };

  return (
    <Tooltip title={content} arrow placement="top" enterTouchDelay={0} onOpen={onOpenChange}>
      <div className={styles.trigger} onClick={(event) => event.stopPropagation()}>
        ?
      </div>
    </Tooltip>
  );
});
