import { formatPrice } from '@rehold-v3/formatters';
import { useMemo, useCallback } from 'react';

import { getChartCandles } from 'entities/Perpetual/api';

import { SUPPORTED_RESOLUTIONS, DEFAULT_SYMBOL_INFO } from '../TVChartContainer/constants';

import { subscribeOnStream, unsubscribeFromStream } from './streaming';
import { Bar, SymbolInfo } from './types';
import { mapOffsetToTimezone } from './utils';

const lastBarsCache = new Map<string, Bar>();
const prevBarsCache = new Map<string, Bar>();

export default function useTVDatafeed() {
  const onReady: TradingView.IBasicDataFeed['onReady'] = useCallback(async (callback) => {
    setTimeout(() =>
      callback({
        supports_marks: false,
        supports_timescale_marks: false,
        supports_time: true,
        supported_resolutions: Object.keys(SUPPORTED_RESOLUTIONS) as any,
      }),
    );
  }, []);

  const resolveSymbol: TradingView.IDatafeedChartApi['resolveSymbol'] = useCallback(
    async (symbol, onSymbolResolvedCallback, onResolveErrorCallback) => {
      try {
        const pricescale = 10 ** formatPrice({ from: symbol, to: 'usdt', value: '0' }).split('.')[1].length;

        onSymbolResolvedCallback({
          ...DEFAULT_SYMBOL_INFO,
          name: symbol,
          ticker: symbol,
          timezone: mapOffsetToTimezone((-new Date().getTimezoneOffset()).toString()),
          pricescale,
        });
      } catch (e: any) {
        onResolveErrorCallback(e.message);
      }
    },
    [],
  );

  const getBars: TradingView.IDatafeedChartApi['getBars'] = useCallback(
    async (symbolInfo, resolution, periodParams, onHistoryCallback, onErrorCallback) => {
      try {
        const { from, to, firstDataRequest } = periodParams;
        const resolutionUrl = SUPPORTED_RESOLUTIONS[resolution];
        const symbolUrl = symbolInfo.ticker ?? '';
        const fromUrl = new Date(from * 1000).toISOString();
        const toUrl = new Date(to * 1000).toISOString();

        const data = await getChartCandles({
          symbol: symbolUrl,
          resolution: resolutionUrl,
          fromDate: fromUrl,
          toDate: toUrl,
        });

        if (data.t.length === 0) {
          onHistoryCallback([], { noData: true });
          return;
        }

        const barsKey = `${symbolInfo.ticker}-${resolution}`;
        const bars: Bar[] = [];

        for (let i = 0; i < data.t.length; ++i) {
          bars.push({
            time: data.t[i] * 1000,
            low: data.l[i],
            high: data.h[i],
            open: data.o[i],
            close: data.c[i],
          });
        }

        if (firstDataRequest) {
          lastBarsCache.set(barsKey, {
            ...bars[bars.length - 1],
          });
          prevBarsCache.set(barsKey, {
            ...bars[bars.length - 2],
          });
        }

        onHistoryCallback(bars, { noData: false });
      } catch (error: any) {
        onErrorCallback(error);
      }
    },
    [],
  );

  return useMemo(
    () => ({
      datafeed: {
        onReady,
        resolveSymbol,
        getBars,
        subscribeBars: (
          symbolInfo: SymbolInfo,
          resolution: string,
          onRealtimeCallback: (e: Bar) => void,
          subscriberUID: string,
          onResetCacheNeededCallback: any,
        ) => {
          const barsKey = `${symbolInfo.ticker}-${resolution}`;

          subscribeOnStream({
            symbolInfo,
            resolution,
            onRealtimeCallback,
            subscriberUID,
            onResetCacheNeededCallback,
            lastDailyBar: lastBarsCache.get(barsKey)!,
            prevDailyBar: prevBarsCache.get(barsKey)!,
          });
        },
        unsubscribeBars: unsubscribeFromStream,
      },
    }),
    [],
  );
}
