import { ConnectKitButton } from '@rehold-v3/connectkit';
import { Event, track } from '@rehold-v3/data-layer-client';
import { observer } from 'mobx-react-lite';
import React, { memo, useDeferredValue } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useSubmit } from 'widgets/Perpetuals/lib';

import { HotjarEvents, trackHotjar } from 'features/HotJar';

import { usePerpetualForm } from 'entities/Perpetual';
import { PerpetualFormValues } from 'entities/Perpetual/model';

import { useAccount } from 'shared/hooks';
import { Button } from 'shared/ui';

// TODO: FIX ME
// import './index.scss';

export const PerpetualsFormSubmitButton: React.FC = memo(() => {
  const { control } = useFormContext<PerpetualFormValues>();
  const values = useWatch({ control });
  const { inputAmount, ...rest } = values;

  const defferedInputAmount = useDeferredValue(inputAmount);

  return <InnerSubmitButton {...(rest as PerpetualFormValues)} inputAmount={defferedInputAmount as string} />;
});

export const InnerSubmitButton: React.FC<PerpetualFormValues> = observer((values) => {
  const location = useLocation();
  const { isConnected } = useAccount();

  const { state } = usePerpetualForm();

  const { t } = useTranslation();

  const {
    formState: { isSubmitting, errors },
    handleSubmit,
  } = useFormContext<PerpetualFormValues>();

  const { isDisabled, isLoading, onSubmit } = useSubmit();

  const onSubmitPress = () => {
    if (state.tariff) {
      track(Event.PERPETUAL_CREATE_CLICKED, {
        baseTicker: state.tariff.baseTicker,
        quoteTicker: state.tariff.quoteTicker,
        inputAmount: state.inputAmount,
        inputTicker: state.inputTicker,
        isShort: state.isShort,
        leverage: state.leverage,
      });
    }

    handleSubmit(onSubmit)();
  };

  let button = (
    <>
      <Button
        className="perpetuals-form-submit-button"
        variant="primary"
        onClick={onSubmitPress}
        disabled={isDisabled || !+values.inputAmount || !!errors.inputAmount || !!errors.leverage || !!errors.root}
        loading={isSubmitting || isLoading}
      >
        {t('perpetuals.form.createPosition')}
      </Button>
    </>
  );

  if (!isConnected) {
    button = (
      <ConnectKitButton.Custom>
        {({ show }) => {
          const onPress = () => {
            trackHotjar(HotjarEvents.WALLET_CONNECT_CLICK);
            track(Event.WALLET_CONNECT_CLICKED, { from: location.pathname });
            show?.();
          };

          return (
            <Button mt={3} variant="primary" onClick={onPress} data-id="connect-from-create">
              {t('modals.connectWallet.title')}
            </Button>
          );
        }}
      </ConnectKitButton.Custom>
    );
  }

  // if (isSmallMobile) {
  // needs to be rendered in body because of iOS overflow scrolling bug
  // Issue: https://rehold.youtrack.cloud/issue/DEV-196/Forma-sozdaniya-duala-perekryvaet-knopku-Start-Now-na-Iphone-SE
  // Resources:
  // - https://gist.github.com/nicolaskopp/637aa4e20c66fe41a6ea2a0773935f6e
  // - https://www.bram.us/2021/07/08/the-large-small-and-dynamic-viewports/
  // - https://stackoverflow.com/questions/55272266/webkit-fill-available-is-not-working-as-expected-top-and-bottom-of-divs-verti
  // - https://medium.com/quick-code/100vh-problem-with-ios-safari-92ab23c852a8
  // Some of elements in layout don't fit correctly into 100vh.
  //   return ReactDOM.createPortal(button, document.body);
  // }

  return button;
});
