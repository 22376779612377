import { Locale, enGB, ru, id, es, pt, vi, de, fr, it, zhCN, zhTW } from 'date-fns/locale';

export const dateFnsLocales: { [key: string]: Locale } = {
  // ko: kr,
  cn_tw: zhTW,
  de,
  en: enGB,
  es,
  fr,
  in: id,
  it,
  pt,
  ru,
  // ja: jp,
  vi,
  zh_cn: zhCN,
};
