import { Event, track } from '@rehold-v3/data-layer-client';
import classnames from 'classnames';
import { observer } from 'mobx-react-lite';
import React, { useCallback, useState, useMemo, FC, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { DualAssetType } from 'entities/Dual';
import { useDualAssets } from 'entities/Dual/lib';

import { useDebounce, useToggle, useWidget } from 'shared/hooks';
import { updateQueryParam } from 'shared/lib';
import { Box } from 'shared/ui';

import { DualsList as DualsTable } from './DualsTable/DualsTable';
import { FilterAsset } from './FilterAsset/FilterAsset';
import { FilterChains } from './FilterChains/FilterChains';
import {
  FilterQuoteTicker,
  OVERRIDDEN_MAP,
  OVERRIDDEN_TICKERS,
  TICKERS_ORDER,
} from './FilterQuoteTicker/FilterQuoteTicker';
import './index.scss';

const SEARCH_DEBOUNCE_MS = 100;

const CHAIN_ID_PARAM = 'chainId';
const QUOTE_TICKER_PARAM = 'quoteTicker';

const sortTickers = (a: string, b: string) => {
  if (TICKERS_ORDER[a] && TICKERS_ORDER[b]) return TICKERS_ORDER[a] - TICKERS_ORDER[b];
  if (TICKERS_ORDER[a]) return -1;
  if (TICKERS_ORDER[b]) return 1;
  return 0;
};

export const DualsList: FC = observer(() => {
  const navigate = useNavigate();
  const isWidget = useWidget();

  const [searchParams] = useSearchParams();
  const paramChainId = searchParams.get(CHAIN_ID_PARAM);
  const paramQuoteTicker = searchParams.get(QUOTE_TICKER_PARAM);

  const [selectedChainId, setSelectedChainId] = useState<number | undefined>(
    paramChainId ? Number(paramChainId) : undefined,
  );

  const assets = useDualAssets({ chainId: selectedChainId });

  const quoteTickers = useMemo(() => {
    const values = [
      ...new Set(
        assets
          ?.filter((asset) => (selectedChainId && asset.chainId === selectedChainId) || !selectedChainId)
          .map((item) => OVERRIDDEN_MAP[item.quoteTicker] || item.quoteTicker),
      ),
    ];
    return values.sort(sortTickers);
  }, [assets, selectedChainId]);

  const defaultSelectedTicker =
    quoteTickers.find((ticker) => paramQuoteTicker && ticker === paramQuoteTicker) || quoteTickers[0];

  const [selectedQuoteTicker, setSelectedQuoteTicker] = useState<string>(defaultSelectedTicker);
  const [searchText, setSearchText] = useState('');
  const [isShowSearch, , setIsShowSearch] = useToggle(false);

  useEffect(() => {
    updateQueryParam(QUOTE_TICKER_PARAM, selectedQuoteTicker);
  }, [selectedQuoteTicker]);

  useEffect(() => {
    updateQueryParam(CHAIN_ID_PARAM, selectedChainId?.toString());

    if (selectedQuoteTicker !== null && !quoteTickers.includes(selectedQuoteTicker || '')) {
      setSelectedQuoteTicker(quoteTickers[0]);
    }
  }, [selectedChainId]);

  const onChainFilterPress = useCallback(
    (chainId?: number) => {
      setSelectedChainId(chainId);
      track(Event.DUAL_ASSET_FILTERED, {
        chainId: (chainId || null) as number | undefined,
      });
    },
    [selectedChainId, selectedQuoteTicker],
  );

  const onQuoteFilterPress = useCallback(
    (ticker: string) => {
      setSelectedQuoteTicker(ticker);
      track(Event.DUAL_ASSET_FILTERED, { quoteTicker: ticker });
    },
    [selectedQuoteTicker],
  );

  const handlePress = useCallback(
    (item: DualAssetType) => {
      track(Event.DUAL_ASSET_CLICKED, {
        apr: item.apr,
        baseTicker: item.baseTicker,
        quoteTicker: item.quoteTicker,
        chainId: item.chainId,
      });
      const baseTicker = item.baseTicker.toLowerCase();
      const quoteTicker = item.quoteTicker.toLowerCase();

      navigate(
        `${isWidget ? '/widgets' : ''}/dual/${item.chainId}/${baseTicker}/${quoteTicker}${
          isWidget ? '?backBtn=true' : ''
        }`,
      );
    },
    [isWidget],
  );

  const [debouncedText, setDebouncedText] = useState('');

  const debouncedSearch = useDebounce(debouncedText, SEARCH_DEBOUNCE_MS);

  const filteredAssets = useMemo(() => {
    if (!selectedQuoteTicker && !debouncedSearch) {
      return assets;
    }

    const text = debouncedSearch.toLowerCase();
    const escapedInput = text.replace(/[.*+?^${}()|[\]\\_|\\/|]/g, '');
    const inputRegexp = new RegExp(`[a-zA-Z_]*${escapedInput}[a-zA-Z_]*`, 'i');

    return assets
      ?.filter((a) => {
        const isMatchSearchText = text
          ? !!inputRegexp.test(`${a.baseTicker}${a.quoteTicker}`.replaceAll('.', ''))
          : true;

        const isSelectedQuoteTicker =
          selectedQuoteTicker && !text
            ? OVERRIDDEN_TICKERS[selectedQuoteTicker.toLowerCase()]?.includes(a.quoteTicker.toLowerCase()) ||
              selectedQuoteTicker.toLowerCase() === a.quoteTicker.toLowerCase()
            : true;

        return isSelectedQuoteTicker && isMatchSearchText;
      })
      .sort((firstAsset, secondAsset) => {
        const isFirstAssetWithQuote =
          OVERRIDDEN_TICKERS[selectedQuoteTicker?.toLowerCase() || '']?.includes(
            firstAsset.quoteTicker.toLowerCase(),
          ) || selectedQuoteTicker?.toLowerCase() === firstAsset.quoteTicker.toLowerCase();
        const isSecondAssetWithQuote =
          OVERRIDDEN_TICKERS[selectedQuoteTicker?.toLowerCase() || '']?.includes(
            secondAsset.quoteTicker.toLowerCase(),
          ) || selectedQuoteTicker?.toLowerCase() === secondAsset.quoteTicker.toLowerCase();

        if ((isFirstAssetWithQuote && isSecondAssetWithQuote) || (!isFirstAssetWithQuote && !isSecondAssetWithQuote))
          return 0;

        if (isFirstAssetWithQuote) return -1;

        return 1;
      });
  }, [assets, selectedQuoteTicker, debouncedSearch]);

  return (
    <div className={classnames('dashboard-container', { widget: isWidget })}>
      <Box className="filter-container" zIndex={1}>
        <FilterAsset
          setIsShowSearch={setIsShowSearch}
          isActive={isShowSearch}
          onSearch={setSearchText}
          setDebouncedText={setDebouncedText}
          searchText={searchText}
        >
          <FilterChains selected={selectedChainId} onSelect={onChainFilterPress} />
        </FilterAsset>
        <FilterQuoteTicker selected={selectedQuoteTicker} onSelect={onQuoteFilterPress} tickers={quoteTickers} />
      </Box>
      <DualsTable assets={filteredAssets} onSelect={handlePress} />
    </div>
  );
});
