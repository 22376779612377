import { formatAmount } from '@rehold-v3/formatters';
import BN from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { useCallback, FC } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useBalance } from 'wagmi';

import { useGetDualTokenByTickerAndChain } from 'entities/Token/model/useGetDualTokenByTickerAndChain';

import { useAccount, useIsMobile } from 'shared/hooks';
import { Box, Text, Spinner } from 'shared/ui';

import { DashboardFormValues } from '../model/form';

interface UserWalletBalanceProps {
  onPress: () => void;
  ticker: string;
}

export const UserWalletBalance: FC<UserWalletBalanceProps> = observer(({ onPress, ticker }) => {
  const { t } = useTranslation();

  const { control } = useFormContext<DashboardFormValues>();
  const [chainId] = useWatch({
    control,
    name: ['chainId'],
  });

  const { address, isConnected } = useAccount();
  const token = useGetDualTokenByTickerAndChain(ticker, chainId);

  const { data, isError, isFetching, refetch } = useBalance({
    address,
    chainId,
    token: token?.address,
    enabled: !!address,
  });

  const handleRefetch = useCallback(() => refetch(), [refetch]);

  const isMobile = useIsMobile();

  if (!isConnected) {
    return null;
  }

  return (
    <Box flexDirection="row" alignItems="center" mb={-4}>
      <Text text="app-12-regular" mr={8}>
        {t('common.balance')}:{' '}
      </Text>

      {data?.formatted && data?.symbol && (
        <Box onClick={onPress} data-id="user-wallet-balance">
          <Text text="app-12-regular" color="primary-01">
            {formatAmount({ roundingMode: BN.ROUND_DOWN, symbol: data.symbol.toLowerCase(), value: data.formatted })}{' '}
            <Text sx={{ opacity: 0.6 }} text="app-12-regular" color="primary-01">
              {data.symbol}
            </Text>
          </Text>
        </Box>
      )}
      {isError && (
        <Box ml={data?.formatted ? 8 : 0} flexDirection="row">
          {!isMobile && (
            <Text text="app-18-medium" color="red-01">
              {t('common.fetchError')}
            </Text>
          )}
          <Box onClick={handleRefetch} data-id="refetch-user-wallet-balance">
            <Text text="app-18-medium" color="red-01" fontWeight="bold">
              {t('common.fetchRetry')}
            </Text>
          </Box>
        </Box>
      )}
      {isFetching && (
        <Box ml={8}>
          <Spinner size={14} />
        </Box>
      )}
    </Box>
  );
});
