import useResizeObserver from '@react-hook/resize-observer';
import { formatPrice } from '@rehold-v3/formatters';
import BN, { BigNumber } from 'bignumber.js';
import * as d3 from 'd3';
import { observer } from 'mobx-react-lite';
import React, { useEffect, useLayoutEffect } from 'react';

import type { LimitDual as LimitDualType } from 'features/LimitsDual';

import { Box, Text } from 'shared/ui';

import { useLimitDualChartData } from './useLimitDualChardData';

interface DualChartProps {
  cb?: () => void;
  dual: LimitDualType;
}

export const LimitChart: React.FC<DualChartProps> = observer(({ dual, cb }) => {
  const svgRef = React.useRef<SVGSVGElement | null>(null);
  const boxRef = React.useRef<HTMLDivElement | null>(null);

  const [svgSize, setSvgSize] = React.useState<{ height: number; width: number } | null>(null);

  useLayoutEffect(() => {
    if (boxRef.current) {
      const { height, width } = boxRef.current.getBoundingClientRect();
      setSvgSize({ height, width });
    }
  }, []);

  useResizeObserver(boxRef, ({ borderBoxSize }) => {
    const { blockSize: height, inlineSize: width } = borderBoxSize[0];
    setSvgSize({ height, width });
  });

  const BADGE_HEIGHT = 16;
  const PROFIT_BLOCK_WIDTH = 130;
  const TICKER_ICON_WIDTH = 24;
  const PARENT_PADDING = 24;

  const marginXRight = PROFIT_BLOCK_WIDTH - TICKER_ICON_WIDTH / 2 + PARENT_PADDING; //
  const marginY = BADGE_HEIGHT / 2;

  const viewboxHeight = svgSize?.height || 1;
  const viewboxWidth = svgSize?.width || 1;

  const { chartData, chartDataWithLastPoint, dualFinished, dualInitialPrice, dualStartedAt, lastPoint } =
    useLimitDualChartData(dual);

  useEffect(() => {
    const isDown = dual.initialPrice > dual.targetPrice;
    if (isDown ? BigNumber(dual.targetPrice).gte(lastPoint.price) : BigNumber(dual.targetPrice).lte(lastPoint.price)) {
      cb?.();
    }
  }, [dual.targetPrice, lastPoint.price, dual.initialPrice, cb]);

  const [currentPricePosition, setCurrentPricePosition] = React.useState<null | number>(null);
  const [initialPricePosition, setInitialPricePosition] = React.useState<null | number>(null);

  const currentPriceDiff = React.useMemo(() => {
    if (!currentPricePosition || !initialPricePosition) {
      return null;
    }

    const diff = currentPricePosition - initialPricePosition;

    const pos =
      diff <= BADGE_HEIGHT && diff >= 0
        ? initialPricePosition + BADGE_HEIGHT
        : diff <= 0 && diff >= -BADGE_HEIGHT
        ? initialPricePosition - BADGE_HEIGHT
        : currentPricePosition;

    return `${(pos / viewboxHeight) * 100}%`;
  }, [currentPricePosition, viewboxHeight, initialPricePosition]);

  const initialPriceDiff = React.useMemo(() => {
    if (!initialPricePosition) {
      return null;
    }

    return `${(initialPricePosition / viewboxHeight) * 100}%`;
  }, [initialPricePosition, viewboxHeight]);

  const [minDate = 0, maxDate = 0] = React.useMemo(
    () => d3.extent(chartDataWithLastPoint, (d) => d.createdAt),
    [chartDataWithLastPoint],
  );
  const xScale = React.useMemo(
    () =>
      d3
        .scaleLinear()
        .domain([minDate, maxDate])
        .range([0, viewboxWidth - marginXRight]),
    [minDate, maxDate, viewboxWidth, marginXRight],
  );

  const [priceMin = 0, priceMax = 0] = React.useMemo(
    () => d3.extent(chartDataWithLastPoint, (d) => d.price),
    [chartDataWithLastPoint],
  );

  const leftDashedLineD = React.useMemo(() => {
    const localData = [
      { x: xScale(dualStartedAt), y: 0 },
      { x: xScale(dualStartedAt), y: viewboxHeight },
    ];

    const lineTransformer = d3
      .line<(typeof localData)[0]>()
      .x((d) => d.x)
      .y((d) => d.y);

    return lineTransformer(localData);
  }, [viewboxHeight, dualStartedAt, xScale]);

  const yScale = React.useMemo(() => {
    const priceMinDomain = BN(priceMin).gt(dualInitialPrice) ? dualInitialPrice : priceMin;
    const priceMaxDomain = BN(priceMax).gt(dualInitialPrice) ? priceMax : dualInitialPrice;

    const isLargeDiff = BN(priceMaxDomain).div(priceMinDomain).multipliedBy(100).minus(100).abs().gt(5);

    if (!isLargeDiff) {
      return d3
        .scaleLinear()
        .domain([priceMinDomain, priceMaxDomain])
        .range([viewboxHeight - marginY, marginY]);
    }

    const priceMinDomainInterp = BN(priceMin).gt(dualInitialPrice) ? priceMin * 0.98 : priceMin;
    const priceMaxDomainInterp = BN(priceMax).gt(dualInitialPrice) ? priceMax : priceMax * 1.02;

    return d3
      .scaleLinear()
      .domain([priceMinDomainInterp, priceMaxDomainInterp])
      .range([viewboxHeight - marginY, marginY])
      .interpolate((a, b) => (k) => {
        let t = k;
        if (t > 1) {
          t = 1.02;
        }
        if (t < 0) {
          t = -0.02;
        }
        return a * (1 - t) + b * t;
      });
  }, [dualInitialPrice, priceMax, priceMin, marginY, viewboxHeight]);

  React.useEffect(() => {
    setInitialPricePosition(yScale(+dualInitialPrice));
    setCurrentPricePosition(yScale(+lastPoint.price));
  }, [dualInitialPrice, lastPoint.price, yScale]);

  const lineTransformer = React.useMemo(
    () =>
      d3
        .line<(typeof chartData)[0]>()
        .x((d) => xScale(d.createdAt))
        .y((d) => yScale(d.price)),
    [xScale, yScale],
  );

  const historyData = chartData.filter((d) => d.createdAt <= dualStartedAt);

  const dualLineD = React.useMemo(
    () => lineTransformer(chartData.filter((d) => d.createdAt >= dualStartedAt)),
    [chartData, lineTransformer, dualStartedAt],
  );

  const lastPointLineD = React.useMemo(() => {
    const localData = chartData.length ? [chartData[chartData.length - 1], lastPoint] : [lastPoint];

    return lineTransformer(localData);
  }, [chartData, lastPoint, lineTransformer]);

  const initialPriceLineD = React.useMemo(() => {
    const localData = [
      { price: +dualInitialPrice, x: xScale(dualStartedAt) },
      { price: +dualInitialPrice, x: viewboxWidth - PARENT_PADDING },
    ];

    const lineTransformer = d3
      .line<(typeof localData)[0]>()
      .x((d) => d.x)
      .y((d) => yScale(d.price));

    return lineTransformer(localData);
  }, [viewboxWidth, dualInitialPrice, yScale, dualStartedAt, xScale]);

  const historyLineD = React.useMemo(
    () => lineTransformer(chartData.filter((d) => d.createdAt <= dualStartedAt)),
    [chartData, lineTransformer, dualStartedAt],
  );

  const currentPriceLineD = React.useMemo(() => {
    const localData = [
      { price: +lastPoint.price, x: xScale(lastPoint.createdAt) },
      { price: +lastPoint.price, x: viewboxWidth - PARENT_PADDING - 6 },
    ];

    const lineTransformer = d3
      .line<(typeof localData)[0]>()
      .x((d) => d.x)
      .y((d) => yScale(d.price));

    return lineTransformer(localData);
  }, [viewboxWidth, yScale, xScale, lastPoint]);

  return (
    <Box height={136} position="relative" ref={boxRef}>
      <svg ref={svgRef} height="100%" width="100%">
        {historyLineD && <path fill="none" stroke="#632BDC" strokeWidth={1} strokeLinecap="round" d={historyLineD} />}
        {leftDashedLineD && (
          <path
            fill="none"
            strokeDasharray="2 3"
            stroke="url(#paint0_linear_3233_5429)"
            strokeWidth={1}
            strokeLinecap="round"
            d={leftDashedLineD}
          />
        )}
        {dualLineD && <path fill="none" stroke="white" strokeWidth={1} strokeLinecap="round" d={dualLineD} />}
        {lastPointLineD && <path fill="none" stroke="white" strokeWidth={1} strokeLinecap="round" d={lastPointLineD} />}

        {/* target white point */}
        <circle fill="#05F283" cx={xScale(dualStartedAt)} cy={yScale(dualInitialPrice)} r={3} />
        {/* start white point */}
        <circle
          fill="white"
          cx={xScale(dualStartedAt)}
          cy={yScale(historyData[historyData.length - 1]?.price || 0)}
          r={3}
        />
        {/* white line */}
        {initialPriceLineD && (
          <path fill="none" stroke="#05F283" strokeWidth={0.5} strokeLinecap="round" d={initialPriceLineD} />
        )}

        <circle fill="white" cx={xScale(lastPoint.createdAt)} cy={yScale(lastPoint.price)} r={3} />
        {/* current price animation */}
        {!dualFinished && (
          <circle fill="white" cx={xScale(lastPoint.createdAt)} cy={yScale(lastPoint.price)} r={3}>
            {/* <circle cx={chartStart} cy={middleY} fill="none" r={3} stroke="var(--color-white-01)" strokeWidth={2}> */}
            <animate attributeName="r" from={3} to={6} dur="1.5s" begin="0s" repeatCount="indefinite" />
            <animate attributeName="opacity" from={0.6} to={0} dur="1.5s" begin="0s" repeatCount="indefinite" />
            {/* </circle> */}
          </circle>
        )}
        {/* green line */}
        {currentPriceLineD && (
          <path fill="none" stroke="white" strokeWidth={0.5} strokeLinecap="round" d={currentPriceLineD} />
        )}

        <linearGradient id="paint0_linear_3233_5429" x1="0.5" y1="138" x2="0.5" y2="-2" gradientUnits="userSpaceOnUse">
          <stop stopColor="#632BDC" stopOpacity="0" />
          <stop offset="0.5" stopColor="#632BDC" />
          <stop offset="1" stopColor="#632BDC" stopOpacity="0" />
        </linearGradient>
      </svg>
      {initialPriceDiff && (
        <Box position="absolute" right={PARENT_PADDING} top={initialPriceDiff}>
          <Box position="relative" top={-BADGE_HEIGHT / 2}>
            <Box height={16} borderRadius={20} px={6} justifyContent="center" bg="primary-01">
              <Text text="app-12-medium" color="background-01">
                {formatPrice({ from: dual.baseTicker, to: dual.quoteTicker, value: dual.targetPrice })}{' '}
              </Text>
            </Box>
          </Box>
        </Box>
      )}

      {currentPriceDiff && (
        <Box position="absolute" right={PARENT_PADDING} style={{ top: currentPriceDiff }}>
          <Box position="relative" top={-BADGE_HEIGHT / 2}>
            <Box height={16} borderRadius={20} px={6} justifyContent="center" bg="white-01">
              <Text text="app-12-medium" color="background-01">
                {formatPrice({
                  from: dual.baseTicker,
                  to: dual.quoteTicker,
                  value: chartDataWithLastPoint[chartDataWithLastPoint.length - 1].price,
                })}
              </Text>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
});
