import { Event, track } from '@rehold-v3/data-layer-client';
import { useCallback, useEffect, useMemo } from 'react';

import { useSignAuthMessage } from 'features/Auth';

import { useClaimPointsMutation, useGetPointsSettingsQuery, useGetPointsStatsQuery } from 'entities/Points';

import { useAccount } from 'shared/hooks';

export const usePoints = () => {
  const { address } = useAccount();

  const { data: pointsStats, isError, isFetching, isInitialLoading, refetch } = useGetPointsStatsQuery();

  const {
    data: settings,
    isError: isErrorSettings,
    isFetching: isFetchingSettings,
    isInitialLoading: isLoadingSettings,
    refetch: refetchSetting,
  } = useGetPointsSettingsQuery();

  const { isLoading: isLoadingClaim, mutateAsync: claimReferral } = useClaimPointsMutation();

  const { getSignature } = useSignAuthMessage();

  const isDisabledClaim =
    !!pointsStats && !!pointsStats.nextClaimAt && Date.parse(pointsStats.nextClaimAt) > Date.now();

  const claimAfter = useMemo(
    () => (isDisabledClaim ? Date.parse(pointsStats.nextClaimAt) - Date.now() : 0),
    [isDisabledClaim, pointsStats],
  );

  const code = pointsStats?.code ?? '';

  const claim = useCallback(
    async (input: { recaptchaV2: string; turnstile?: string } | { recaptchaV2?: string; turnstile: string }) => {
      const { recaptchaV2, turnstile } = input;

      track(Event.POINT_CLAIM_CLICKED, {
        address: address!,
        amount: settings?.daily || 0,
      });

      const signature = await getSignature();

      if (!signature) return;

      await claimReferral({ signature, recaptchaV2, turnstile });

      track(Event.POINT_CLAIM_SUCCEEDED, {
        address: address!,
        amount: settings?.daily || 0,
      });

      refetch();
      refetchSetting();
    },
    [address, getSignature, claimReferral, settings],
  );

  useEffect(() => {
    if (claimAfter === 0) return;

    const timeout = setTimeout(() => {
      refetch();
    }, claimAfter);

    return () => clearTimeout(timeout);
  }, [claimAfter]);

  const pointsData = {
    address,
    stats: pointsStats,
    isStatsError: isError && !isFetching,
    isStatsFetching: isFetching,
    isStatsLoading: isInitialLoading,
    statsRefetch: () => {
      refetch();
    },
    code,
    link: code ? `rehold.io/j/${code}` : '',
    claim,
    claimAfter,
    isClaimDisabled: isDisabledClaim,
    isClaimLoading: isLoadingClaim,
    settings,
    isSettingsError: isErrorSettings,
    isSettingsFetching: isFetchingSettings,
    isSettingsLoading: isLoadingSettings,
    settingsRefetch: () => {
      refetchSetting();
    },
  };

  return pointsData;
};
