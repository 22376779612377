import { Event, track } from '@rehold-v3/data-layer-client';
import { useQueryClient } from '@tanstack/react-query';
import BigNumber from 'bignumber.js';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useSetAutoReplayMutation } from 'features/AutoReplay';

import { DualType, getTariffId } from 'entities/Dual';
import { useDualTariffByChain } from 'entities/Dual/lib/hooks/useDualTariffByChain';
import { useRate } from 'entities/Rates';
import { useGetDualTokenAddress } from 'entities/Token/model/useGetDualTokenAddress';

import { useToggle, useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { DEFAULT_ERROR } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { notifyError } from 'shared/lib/notifications';
import { Button, PageHeader, Tooltip, Modal } from 'shared/ui';

import { useReplay } from './lib/useReplay';
import { ModalContent } from './ui/ModalContent';

type Props = {
  dual: DualType;
  fromLocation?: string;
  isDisabled?: boolean;
  isEnabledAutoReplay?: boolean;
  isLoading?: boolean;
  onClose?: () => void;
  onOpen?: () => void;
  type?: 'button' | 'default' | 'modal';
};

export const ReplayDual: FC<Props> = observer(
  ({ dual, fromLocation = 'duals', isDisabled, isEnabledAutoReplay, isLoading, onClose, onOpen, type = 'default' }) => {
    const { t } = useTranslation();
    const { address } = useAccount();
    const { chainId } = useNetwork();
    const queryClient = useQueryClient();
    const [isOpen, , setOpen] = useToggle();

    const { baseTicker, outputAmount, outputTicker, quoteTicker, stakingPeriod } = dual;

    const rate = useRate({
      from: baseTicker,
      skip: (!isOpen && type === 'default') || type === 'button',
      to: quoteTicker,
      inputTicker: outputTicker,
    });

    const inputQuoteAmount = BigNumber(dual.outputAmount)
      .multipliedBy(BigNumber(rate?.price || 0))
      .toString();

    const inputBaseAmount = BigNumber(dual.outputAmount)
      .div(BigNumber(rate?.price || 0))
      .toString();

    const baseToken = useGetDualTokenAddress(baseTicker, chainId)!;
    const quoteToken = useGetDualTokenAddress(quoteTicker, chainId)!;

    const { tariffs } = useDualTariffByChain({ baseToken, quoteToken, chainId });

    const tariff = tariffs.find(
      (t) => t.stakingPeriod === stakingPeriod && t.baseTicker === baseTicker && t.quoteTicker === quoteTicker,
    );

    const realTariffId = getTariffId(tariff?.id || '');

    const newDual = {
      ...dual,
      inputAmount: outputAmount,
      inputBaseAmount: baseTicker === outputTicker ? outputAmount : inputBaseAmount,
      inputQuoteAmount: quoteTicker === outputTicker ? outputAmount : inputQuoteAmount,
      inputTicker: outputTicker,
      stakingPeriod: tariff?.stakingPeriod || dual.stakingPeriod,
      tariffId: realTariffId,
    };

    const trackParams = {
      address: address!,
      apr: dual.apr,
      baseTicker,
      closedPrice: dual.closedPrice,
      finishAt: dual.finishAt,
      from: fromLocation!,
      id: dual.id,
      initialPrice: rate?.formatted.price || '0',
      inputAmount: dual.inputAmount,
      inputTicker: dual.inputTicker,
      outputAmount,
      outputTicker,
      quoteTicker,
      stakingPeriod: dual.stakingPeriod,
    };

    const isAprChanged = !!tariff?.apr && Number(dual.apr).toFixed(2) !== tariff.apr.toFixed(2);
    const isPeriodChanged = !!tariff?.stakingPeriod && Number(dual.stakingPeriod) !== tariff?.stakingPeriod;
    const isShowChanged = isAprChanged || isPeriodChanged;

    const isCallReplayEnabled = !isDisabled;

    const { isLoading: loading, onReplay } = useReplay({
      dual,
      enabled: isCallReplayEnabled,
      isShowChanged,
      tariffId: realTariffId || '0',
      trackParams,
    });

    const isReplayLoading = loading || isLoading;
    const isReplayDisabled = !isCallReplayEnabled || dual.l2Claimed;

    const { changeAutoReplay } = useSetAutoReplayMutation();

    const handleOpenReplay = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.stopPropagation();
      onOpen?.();
      if (type === 'default') setOpen(true);

      track(Event.DUAL_REPLAY_OPENED, { ...trackParams, changed: isShowChanged });
    };

    const handleClose = (e: React.MouseEvent<Element, MouseEvent>) => {
      e.stopPropagation();
      setOpen(false);
    };

    const handleReplay = async (enable: boolean) => {
      try {
        const result = await onReplay(enable);
        if (enable && result) {
          let id: null | string = null;

          if ('id' in result) {
            id = result.id;
          }
          if (id) await changeAutoReplay(id);
        }

        queryClient.invalidateQueries({
          queryKey: [`opened-duals-${chainId}-${address}`],
        });
        queryClient.invalidateQueries({
          queryKey: [`closed-duals-${chainId}-${address}`],
        });
        queryClient.invalidateQueries({
          queryKey: [`notification-list-${address}`],
        });

        if (type === 'default') setOpen(false);

        onClose?.();
        window.scrollTo({ top: 0 });
      } catch (e: any) {
        logger.error(e);

        notifyError({ text: e?.response?.data?._error?.message ?? DEFAULT_ERROR });
      }
    };

    const textError = dual.l2Claimed ? t('dual.replayNotAvailable') : undefined;

    if (type === 'modal') {
      return (
        <ModalContent
          isOpen
          newDual={newDual}
          handleReplay={handleReplay}
          tariff={tariff!}
          oldDual={dual}
          isReplayDisabled={isReplayDisabled}
          isReplayLoading={!!isReplayLoading}
          isAprChanged={isAprChanged}
          isShowChanged={isShowChanged}
          isPeriodChanged={isPeriodChanged}
          isEnabledAutoReplay={isEnabledAutoReplay}
          textError={textError}
        />
      );
    }

    const button = (
      <Tooltip
        title={textError}
        disabled={!textError}
        PopperProps={{
          disablePortal: true,
          modifiers: [
            {
              name: 'preventOverflow',
              options: {
                padding: 12,
              },
            },
          ],
        }}
      >
        <Button
          onClick={handleOpenReplay}
          loading={isReplayLoading}
          disabled={isReplayDisabled}
          mb={16}
          data-id="replay-dual"
        >
          {t('dual.replayDual')}
        </Button>
      </Tooltip>
    );

    if (type === 'button') {
      return button;
    }

    return (
      <>
        {button}
        <Modal
          isOpen={isOpen}
          onClose={handleClose}
          closeIcon
          header={
            <PageHeader showLeftButton={false} px={{ phone: 12, tablet: 16 }}>
              {t('dual.replayDual')}
            </PageHeader>
          }
        >
          <ModalContent
            isOpen={isOpen}
            newDual={newDual}
            isEnabledAutoReplay={isEnabledAutoReplay}
            handleReplay={handleReplay}
            tariff={tariff!}
            oldDual={dual}
            isReplayDisabled={isReplayDisabled}
            isReplayLoading={!!isReplayLoading}
            isAprChanged={isAprChanged}
            isShowChanged={isShowChanged}
            isPeriodChanged={isPeriodChanged}
            textError={textError}
          />
        </Modal>
      </>
    );
  },
);
