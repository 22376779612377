import { Event, track } from '@rehold-v3/data-layer-client';
import { verifyMessage } from 'viem';
import { useSignMessage } from 'wagmi';

import { useGetPointsStatsQuery } from 'entities/Points';
import { useSignupMutation, useUserStore, waitForUserAction } from 'entities/User';
import { authUser } from 'entities/User/api';

import { useAccount } from 'shared/hooks';
import { useNetwork } from 'shared/hooks/network';
import { SIGNATURE_MESSAGE, extractError } from 'shared/lib';
import { logger } from 'shared/lib/logger';
import { notifyAttention } from 'shared/lib/notifications';
import { i18n } from 'shared/locales';

import { storageSignature } from './storageSignature';

export const useSignAuthMessage = () => {
  const { address } = useAccount();
  const { setIsOpenWelcomeModal } = useUserStore();
  const { refetch: statsRefetch } = useGetPointsStatsQuery();

  const { signMessageAsync } = useSignMessage();
  const { network } = useNetwork();
  const { mutateAsync: signup } = useSignupMutation();

  const validateSign = async () => {
    const signAddress = storageSignature.get(address!);

    const isSigned = signAddress
      ? await verifyMessage({
          address: address!,
          message: SIGNATURE_MESSAGE,
          signature: signAddress as `0x${string}`,
        })
      : false;

    return isSigned;
  };

  const getSignature = async () => {
    const isSigned = await validateSign();

    if (!isSigned) {
      try {
        const isSuccess = await waitForUserAction(signAuthMessage(), 'message');

        if (!isSuccess) return null;
      } catch {
        return null;
      }
    }

    const signature = storageSignature.get(address!);
    if (!signature) return null;

    return signature;
  };

  const signAuthMessage = async () => {
    if (!address) {
      logger.error(new Error('not address'));
      return false;
    }
    track(Event.AUTH_ATTEMPTED, { address, chainId: network?.id!, provider: network?.name! });

    try {
      const [signature, isSignedUp] = await Promise.all([
        signMessageAsync({ message: SIGNATURE_MESSAGE }),
        authUser(address)
          .then(() => true)
          .catch((e) => e.response?.status !== 404),
      ]);

      if (!isSignedUp) {
        await signup({ address, signature });
        // setIsOpenWelcomeModal(true);
        statsRefetch();
      }

      storageSignature.set(signature, address);
      track(Event.AUTH_SUCCEEDED, { address, chainId: network?.id!, provider: network?.name! });

      return true;
    } catch (error) {
      notifyAttention({ text: i18n.t('common.userRejectedRequest') });
      track(Event.AUTH_FAILED, {
        address,
        chainId: network?.id!,
        error: extractError(error) ?? 'Unknown error',
        provider: network?.name!,
      });

      return false;
    }
  };

  return { getSignature, signAuthMessage, validateSign };
};
