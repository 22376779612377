import { ConnectKitProvider } from '@rehold-v3/connectkit';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { WagmiConfig } from 'wagmi';

import { useStore } from 'shared/hooks/store';
import { connectKitLanguages } from 'shared/locales';

import { config } from './config';

export const WagmiProvider: React.CFC = observer(({ children }) => {
  const {
    i18n: { language },
  } = useTranslation();

  const { networkStore } = useStore();

  return (
    <WagmiConfig config={config}>
      <ConnectKitProvider
        mode="dark"
        options={{
          initialChainId: networkStore.selectedChainId,
          language: connectKitLanguages[language as keyof typeof connectKitLanguages] || 'en-US',
        }}
        onConnect={({ connectorId }) => {
          if (connectorId) networkStore.setLastConnectorId(connectorId);
        }}
        onDisconnect={() => {
          networkStore.setLastConnectorId(null);
        }}
      >
        {children}
      </ConnectKitProvider>
    </WagmiConfig>
  );
});
