import { useModal } from '@rehold-v3/connectkit';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetCountDualsQuery } from 'entities/Dual/model';

import { useAccount } from 'shared/hooks';
import { PathPatterns } from 'shared/lib';
import { Box, Spinner, Tab, Tabs, Text } from 'shared/ui';

export const DUAL_TABS_SECTION_PARAM = 'section';

export const DualTabs: React.FC = () => {
  const { t } = useTranslation();
  const { isConnected } = useAccount();
  const { data, isLoading } = useGetCountDualsQuery();

  const { pathname } = useLocation();
  const pathRoot = `/${pathname.split('/')[1]}`;

  const { setOpen } = useModal();
  const navigate = useNavigate();

  const handleTabChange = useCallback(
    (_: any, value: string | number | boolean) => {
      const newTab = value.toString() as PathPatterns;

      if (newTab === PathPatterns.Duals && !isConnected) {
        setOpen(true);
        return;
      }

      navigate(newTab + window.location.search);
    },
    [isConnected],
  );

  if (!isConnected && pathRoot === PathPatterns.Duals) {
    navigate(PathPatterns.DualsDashboard);
    return null;
  }

  return (
    <Box
      alignSelf="center"
      width={{ default: '100%', tablet: 568 }}
      mt={16}
      bg="background-01"
      borderColor="secondary-02"
      sx={{ borderWidth: { default: 0, tablet: 1 } }}
      px={{ default: 12, tablet: 0 }}
      borderRadius={24}
      overflow="hidden"
    >
      <Tabs
        style={{
          position: 'relative',
          alignSelf: 'center',
        }}
        onChange={handleTabChange}
        value={pathRoot}
        selectionFollowsFocus
      >
        <Tab label={t('dual.tabs.duals')} value={PathPatterns.DualsDashboard} />
        <Tab label={t('dual.tabs.myDuals')} value={PathPatterns.Duals}>
          {!isLoading && isConnected && (
            <Box
              justifyContent="center"
              alignItems="center"
              minWidth={20}
              height={20}
              bg="primary-01"
              borderRadius={20}
              px={3}
              ml={4}
            >
              <Text color="black-01">{data?.active ?? 0}</Text>
            </Box>
          )}
          {isLoading && isConnected && <Spinner size={20} />}
        </Tab>
      </Tabs>
    </Box>
  );
};
