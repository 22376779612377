import { getImageChain } from 'entities/Chain';

import { CurrencyIcon } from 'shared/ui/CurrencyIcon';

import { routerAbi } from './abi';
import { AppChain } from './types';

export const rootstock: AppChain<'rootstock'> = {
  disabled: false,
  id: 30,
  name: 'Rootstock',
  nativeCurrency: {
    decimals: 18,
    name: 'RBTC',
    symbol: 'RBTC',
  },
  network: 'rootstock',
  tokenStandard: 'Rootstock',
  logo: <CurrencyIcon url={getImageChain('rootstock')} ticker="rbtc" size={24} />,
  renderLogo: (size) => <CurrencyIcon url={getImageChain('rootstock')} ticker="rbtc" size={size} />,
  rpcUrls: {
    default: {
      http: ['https://rootstock.drpc.org'],
    },
    public: {
      http: ['https://rootstock.drpc.org'],
    },
  },
  blockExplorers: {
    default: {
      name: 'Rootstock',
      url: 'https://rootstock.blockscout.com/',
    },
  },
  router: {
    abi: routerAbi,
    address: '0x1D3a30c894F581F0b7295E1bdBED268818318c13',
  },
  vault: {
    address: '0xF09c6F088057A2EB11AEF885640F0440F3710f8b',
  },
};
