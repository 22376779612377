import { Event, track } from '@rehold-v3/data-layer-client';
import { useSwitchNetwork as useSwitchNetworkWagmi } from 'wagmi';

import { logger } from 'shared/lib/logger';

import { useStore } from '../store';

export const useSwitchNetwork = () => {
  const { switchNetworkAsync } = useSwitchNetworkWagmi();
  const { networkStore } = useStore();

  const handleSwitch = (id: number) => {
    track(Event.NETWORK_CHANGE_ATTEMPTED, { chainId: id });
    if (!switchNetworkAsync) {
      // we are not connected
      networkStore.selectNetwork(id);
      track(Event.NETWORK_CHANGE_SUCCEEDED, { chainId: id });
      return;
    }

    networkStore.switchTo(id);
    return switchNetworkAsync(id)
      .then((r) => {
        networkStore.selectNetwork(id);
        track(Event.NETWORK_CHANGE_SUCCEEDED, { chainId: id });
        return r;
      })
      .catch((error) => {
        track(Event.NETWORK_CHANGE_FAILED, { chainId: id });
        logger.error(error);
      })
      .finally(() => {
        networkStore.switchReset();
      });
  };

  return {
    switchNetwork: handleSwitch,
  };
};
