import { Event, track } from '@rehold-v3/data-layer-client';
import { FC, useCallback } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { usePoints } from 'widgets/Points/lib';

import { useAccount } from 'shared/hooks';
import { BoxProps, Box, Text, ButtonCopy, Button } from 'shared/ui';

import { ShareWidget } from '../ShareWidget';

export const PointsInviteFriends: FC = () => {
  const { t } = useTranslation();

  const { isConnected } = useAccount();

  const {
    code: inviteCode,
    link: inviteLink,
    isStatsError,
    statsRefetch,
    settings,
    isSettingsLoading,
    isSettingsError,
    settingsRefetch,
  } = usePoints();

  const onCopy = useCallback(() => {
    track(Event.POINT_SHARE_COPIED, { code: inviteCode });
  }, [inviteCode]);

  return (
    <Box>
      <Box pb={24}>
        {isSettingsError || (!isSettingsLoading && !settings) ? (
          <Box justifyContent="center">
            <Button onClick={settingsRefetch} loading={isSettingsLoading} data-id="refetch-revshare">
              {t('common.fetchRetry')}
            </Button>
          </Box>
        ) : (
          <>
            <Text fontSize={32} lineHeight={42} color="white-01">
              <Trans
                t={t}
                i18nKey="points.inviteFriends"
                components={{
                  Text: <Text color="primary-01" fontSize={32} lineHeight={42} />,
                }}
                values={{
                  profit: (settings?.referral.reduce((acc, profit) => acc + profit, 0) || 0) * 100,
                }}
              />
            </Text>
          </>
        )}
      </Box>
      {isConnected && (
        <>
          <Box pb={24}>
            <Box mb={16} flexDirection="row" alignItems="center">
              <Text>{t('points.shareLink.title')}</Text>
            </Box>
            <InputWrap px={8} py={8}>
              <Box flex={1} flexDirection="row" ml={16}>
                <Box>
                  <Text color="secondary-03">{inviteLink}</Text>
                </Box>
                {isStatsError && (
                  <Box flexDirection="row">
                    {!inviteLink && <Text color="red-01">{t('common.fetchError')} </Text>}
                    <Box onClick={statsRefetch}>
                      <Text color="red-01" fontWeight="bold">
                        {t('common.fetchRetry')}
                      </Text>
                    </Box>
                  </Box>
                )}
              </Box>
              <ButtonCopy
                disabled={!inviteLink}
                variant="secondary"
                copyText={inviteLink}
                onClick={onCopy}
                data-id="copy-invite"
              >
                {t('points.shareLink.copy')}
              </ButtonCopy>
            </InputWrap>
          </Box>
          <Box minHeight={82}>
            <Text mb={16}>{t('points.shareLink.moreWays')}</Text>
            <ShareWidget url={inviteLink} code={inviteCode} />
          </Box>
        </>
      )}
    </Box>
  );
};

export const InputWrap: FC<BoxProps> = (props) => (
  <Box
    flexDirection="row"
    justifyContent="space-between"
    alignItems="center"
    height={64}
    borderColor="secondary-02"
    borderRadius={16}
    {...props}
    sx={{ borderWidth: 1, ...props.sx }}
  />
);
